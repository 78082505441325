export const handleImgObj = (value, isArray = false) => {
  if (!value) return ''
  let valueArr = []
  if (value && JSON.stringify(value) != '[]') {
    valueArr = value.split(',')
    if (!valueArr[valueArr.length - 1]) {
      valueArr.splice(-1)
    }
  }
  if (isArray) {
    return valueArr
  }
  return valueArr[0]
}

// 转化成 img对象 上传组件value 格式
export const transImgsData = defaultValue => {
  if (!defaultValue) return []
  let imgArr = defaultValue
  if (Object.prototype.toString.call(defaultValue[0]) !== '[object Object]') {
    imgArr = handleImgObj(defaultValue, true)
    return imgArr.map((item, index) => ({
      uid: index,
      name: `${index}.png`,
      status: 'done',
      url: item
    }))
  }
  return defaultValue
}

// 是否为云图片 是：返回图片host
export const isCloud = src => {
  const NO_NEED_SIGN_HOST = ['cos.clife.net', 'pic.cos.clife.net', 'skintest.hetyj.com', 'tencentest.hetyj.com']
  const regHost = /^http(s)?:\/\/(.*?)\//
  const host = regHost.exec(src) && regHost.exec(src)[2]
  if (NO_NEED_SIGN_HOST.indexOf(host) !== -1) return host
  return false
}
export const handleLevel = level => {
  let text
  switch (level) {
    case 2:
      text = 'low'
      break
    case 3:
      text = 'high'
      break
    default:
      break
  }
  return text
}

// 转化成 视频video对象 上传组件value 格式
export const transVideosData = defaultValue => {
  if (!defaultValue) return []
  let videoArr = defaultValue
  if (Object.prototype.toString.call(defaultValue[0]) !== '[object Object]') {
    videoArr = handleImgObj(defaultValue, true)
    return videoArr.map((item, index) => ({
      uid: index,
      name: `${index}.mp4`,
      status: 'done',
      url: item
    }))
  }
  return defaultValue
}

// getImgs 获取上传图片的value 逗号拼接成字符串 传给后台
export const getImgsData = fileList => {
  if (!fileList) return ''
  if (!Array.isArray(fileList)) return fileList
  const fileLists = fileList.map(item => (item.url ? item.url.split('?')[0] : item.response ? item.response.data.url : ''))
  return fileLists.join(',')
}
// 获取当前 hostname, 传递参数 true 为获取带 ?ddd (window.location.search) 的地址
export const getHost = flag => {
  if (flag) return `${window.location.protocol}//${window.location.host}${window.location.pathname}${window.location.search}`
  return `${window.location.protocol}//${window.location.host}${window.location.pathname}`.replace('index.html', '')
}


// 判断对象是否 JSON 格式
// export const isJSON = str => {
//   if (typeof str == 'string') {
//     try {
//       JSON.parse(str)
//     } catch (e) {
//       return false
//     }
//     return true
//   }
// }

// 转化 附件对象 只保留name和url 防止图片上传（图片的thumbUrl为base64）导致内容过大
// 将url中的http转换为https，用于附件下载（谷歌浏览器不允许http开头的下载）
// 如果没有上传成功，拿到的数据没有response，下面对数据处理时将arr里url置为''，将url为''的过滤掉就剩下上传成功的数据了
export const transThumbUrl = defaultValue => {
  if (!defaultValue.length) return []
  let arr = defaultValue.map(item => ({
    uid: item.uid,
    name: item.name,
    url: item?.url ? item?.url.replace("https", "http").replace("http", "https") : item?.response?.data?.url.replace("https", "http").replace("http", "https") || '',
    status: "done",
    thumbUrl: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic.pngsucai.com%2F00%2F33%2F10%2F0d0cfbde4a7cb485.jpg'
  }))

  return arr.filter(item => {
    return item.url !== ''
  })
}
/**
 * @description 将小数按照小数点分割为数组
 */
export const splitDecimals = price => {
  // console.log(price);
  if(!price) return ['0', '.00']
  const str = typeof price === 'number' ? JSON.stringify(price) : price
  // 没有小数点，末尾补齐 .00
  if (!str.includes('.')) return [price, '.00']
  const strArr = str.split('.')
  //小数点后只有一位数，末尾补一个 0；超过两位数的暂不做截取
  return [strArr[0], `${strArr[1].length === 1 ? '.' + strArr[1] + '0' : '.' + strArr[1]}`]
}

/**
 * @description 过滤对象 key 为空的的键值对，不过滤0
 */
 export const filterObj = (obj) => {
  if (!obj || JSON.stringify(obj) === "{}") return {};
  let myObj = {};
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop) && obj[prop] !== "" && obj[prop] != null) { // 这里不使用 ===
      myObj[prop] = obj[prop];
    }
  }
  return myObj;
};

/**
 * @description reduce 普通数组去重
 */
 export const uniqueArr = arr => {
  if (!arr || !arr.length) return []
  return arr.reduce(
    (prev, cur) => (prev.includes(cur) ? prev : [...prev, cur]),
    []
  )
}

/**
 * @description 手机号脱敏
 */
export const formatPhone = phone => {
  if (!phone) return ''
  const phoneStr = String(phone)
  if (!/^1[3-9]\d{9}$/.test(phoneStr)) return ''
  return phoneStr.substring(0, 3) + '****' + phoneStr.substring(7, 11)
}
