import React, { useEffect, useRef, useState } from 'react'
import './index.scss'
import goodsTui1 from '../../../../static/img/new-ui/goods-recommend.png'
// import AddCartIcon from '@/static/img/new-ui/add-cart-icon.png'
// import KefuIcon from '@/static/img/new-ui/kefu-icon.png'
import request from '../../../../static/js/http'
import { Link } from 'react-router-dom'
import { splitDecimals } from '../../../../utils/tool'
import cookie from 'react-cookies'
import { message } from 'antd'
function GoodsTui() {
  const [list, setList] = useState([])
  const [isScrolle, setIsScrolle] = useState(false)
  const getList = () => {
    request('post', '/api/goods/goods/otherList', {
      type: '',
      pageNum: 1,
      pageSize: 11,
      priceSort: 7,
      recommend: 1
    }).then(res => {
      if (res.code === 0) {
        setList(res.data.list)
      }
    })
  }
  useEffect(() => {
    getList()
  }, [])
  // 滚动速度，值越小，滚动越快
  // 开始滚动
  const warper = useRef()
  const childDom1 = useRef()
  const scrollCb = () => {
    if (warper.current && childDom1.current) {
      warper.current.scrollLeft >= childDom1.current.scrollWidth
      ? (warper.current.scrollLeft = 0)
      : warper.current.scrollLeft++
    }
  }
  const savedScrollCb = useRef(scrollCb)
  useEffect(() => {
    if(!isScrolle) return
    const timer = setInterval(savedScrollCb.current, 33.34) // 16.7
    return () => clearInterval(timer)
  }, [isScrolle]);

  const hoverHandler = flag => setIsScrolle(flag)
  // 打开“聊一聊窗口”
  const openChatWindow = (event, id) => {
    event.preventDefault()
    if(!id) return
    if (!cookie.load('lj-pc-userInfo')) {
      message.warning('请登录')
    } else {
      const win = window.open('/home/Achat/' + id, 'newwindow', 'width=1200,height=820,left=200,top=123')
      win.focus()
    }
  }

  return (
    <div className='goodsTuiW'>
      <div className='goodsTui' ref={warper}>
        <div className='child' ref={childDom1}>
          {list.map(item => (
            <Link
            target="_blank"
              key={item.goodsId}
              className='goods-link'
              to={'/home/details/' + item.goodsId}>
              <div
                className='goodsTuiItem'
                key={item.goodsId}
                onMouseOver={() => hoverHandler(false)}
                onMouseLeave={() => hoverHandler(true)}>
                <img className='goods-img' src={item.goodsImg} width={198} height={172} alt='' />
                <div className='name ellipsis' title={item.goodsName}>
                  {item.goodsName}
                </div>
                <div className='priceNew'>
                  <span className='priceNewChild1 recommend-price'>
                    <span>¥</span>
                    <span className='ellipsis price-num'>
                      <span className='price-integer'>
                        {splitDecimals(item.productPrice)[0]}
                      </span>
                      <span className='price-decimals'>
                        {splitDecimals(item.productPrice)[1]}
                      </span>
                    </span>
                    <span>/</span>
                    <span>{item.goodsUnit || "斤"}</span>
                  </span>
                  {item.hasCoupon ? (
                    <span className='priceNewChild2'></span>
                  ) : (
                    ''
                  )}
                </div>                
                <div className='shopNameNew'>
                  <div className='shop-name-wrapper'>
                    <object>
                      <Link
                      target="_blank"
                        className='shopName ellipsis'
                        title={item.shopName}
                        to={'/store/' + item.shopId}>
                        {item.shopName}
                      </Link>
                    </object>
                    {/* <img src={KefuIcon} alt="聊一聊" width={16} height={15} className='kefu-icon' onClick={(e) => openChatWindow(e, item.shopPhone)} /> */}
                  </div>
                  {/* <img src={AddCartIcon} alt="购物车" className='add-cart-icon' width={16} height={15} /> */}
                </div>
                {/* <div className="price">
                  <div className="xPrice">
                    优品优选
                  </div>
                  <div className="yPrice">
                    {item.productPrice}
                    <span>元/ {item.goodsUnit ? (item.goodsUnit.indexOf('平方米') > -1 ? '㎡' : item.goodsUnit) : '㎡'}</span>
                  </div>
                </div> */}
              </div>
            </Link>
          ))}
        </div>
        {list.length >= 8 ? (
          <div className='child' ref={childDom1}>
            {list.map(item => (
              <Link
              target="_blank"
                key={item.goodsId}
                className='goods-link'
                to={'/home/details/' + item.goodsId}>
                <div
                  className='goodsTuiItem'
                  key={item.goodsId}
                  onMouseOver={() => hoverHandler(false)}
                  onMouseLeave={() => hoverHandler(true)}>
                    <img className='goods-img' src={item.goodsImg} width={198} height={172} alt='' />
                    <div className='name ellipsis' title={item.goodsName}>
                      {item.goodsName}
                    </div>
                    <div className='priceNew'>
                      <span className='priceNewChild1 recommend-price'>
                        <span>¥</span>
                        <span className='ellipsis price-num'>
                          <span className='price-integer'>
                            {splitDecimals(item.productPrice)[0]}
                          </span>
                          <span className='price-decimals'>
                            {splitDecimals(item.productPrice)[1]}
                          </span>
                        </span>
                        <span>/</span>
                        <span>{item.goodsUnit||"斤"}</span>
                      </span>
                      {item.hasCoupon ? (
                        <span className='priceNewChild2'></span>
                      ) : (
                        ''
                      )}
                    </div>
                  <div className='shopNameNew'>
                  <div className='shop-name-wrapper'>
                    <object>
                      <Link
                      target="_blank"
                        className='shopName ellipsis'
                        title={item.shopName}
                        to={'/store/' + item.shopId}>
                        {item.shopName}
                      </Link>
                    </object>
                    {/* <img src={KefuIcon} alt="聊一聊" width={16} height={15} className='kefu-icon' onClick={(e) => openChatWindow(e, item.shopPhone)} /> */}
                  </div>
                  {/* <img src={AddCartIcon} alt="购物车" className='add-cart-icon' width={18} height={14} /> */}
                  </div>
                  {/* <div className="price">
                  <div className="xPrice">
                    优品优选
                  </div>
                  <div className="yPrice">
                    {item.productPrice}
                    <span>元/ {item.goodsUnit ? (item.goodsUnit.indexOf('平方米') > -1 ? '㎡' : item.goodsUnit) : '㎡'}</span>
                  </div>
                </div> */}
                </div>
              </Link>
            ))}
          </div>
        ) : (
          ''
        )}
      </div>
      <div className='goodsTuiPic'>
        <img src={goodsTui1} alt='' />
      </div>
    </div>
  )
}
export default GoodsTui
