import React, { Component , Fragment} from 'react'
import { Input, Select, Badge, Menu, Dropdown, Empty } from 'antd'
import { NavLink, Link, withRouter } from 'react-router-dom'

import LogoImg from '../../static/img/webljLogo.jpg'
import Gou from '../../static/img/new-ui/gou.png'
// import loginlogo from '../../static/img/indexLogo.png'
// import gou from '../../static/img/gou.png'
// import QRCode from '../../static/img/new-ui/qrcode-small1.png'
// import QRCode from '../../static/img/new-ui/qrcode-text.png'
// import youhuiiicon from '../../static/img/youhuiiicon.png'
// import youhuiquanX from '../../static/img/youhuiquanX.png'
import './index.scss'
import PubSub from 'pubsub-js'
import request from '../../static/js/http'
import cookie from 'react-cookies'
// import LoginModal from "../../components/LoginModal"
import { splitDecimals } from '../../utils/tool'
const { Option } = Select
const { Search } = Input
class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      count: 5,
      list: [],
      tab: [
        { name: '首页', id: '1', path: '/home/index', sta: true },
        { name: '优品优选', id: '2', path: '/home/boutique', sta: false },
        { name: '乡村振兴', id: '3', path: '/home/retail', sta: false },
        { name: '撮合大厅', id: '4', path: '/home/Cuohedating', sta: false },
        // { name: '期货交易', id: '5', path: '/home/futurestrading', sta: false },
        // { name: '行情大厅', id: '6', path: '/home/Market', sta: false },
        { name: '行业资讯', id: '7', path: '/home/MarketConsult', sta: false },
        // { name: '农资商城', id: '8', path: '/home/agriculture', sta: false },
        { name: '农技服务', id: '9', path: '/home/rcserve', sta: false },
        // { name: '金融服务', id: '10', path: '/home/financial', sta: false }
      ],
      searchVal: '',
      historyList: [],
      searchValueTab: '商品',
      // youhuishou: localStorage.getItem('youhuiIcon')
      // shopList: [], // 购物车商品列表
      hideBtnBorder: false, // 购物车按钮边框隐藏
      // cartGoodsNum: 0,
      cartGoodsList: [] // 购物车商品列表
    }
  }
  componentDidMount() {
    this.history(1)
    this.getShopNum()
  }
  componentWillMount() {
    // 订阅消息
    PubSub.subscribe('msg', (msg, data) => {
      this.getShopNum()
    })
  }
  history(type) {
    if (!cookie.load("lj-pc-userInfo")) {
      return;
    } else {
      let memberId = cookie.load("lj-pc-userInfo").memberId;
      request("get", "/api/goods/search/list", {
        memberId,
        type:type?1:0,
      }).then((res) => {
        this.setState({
          // historyList: res.data[0].list
          historyList: res.data && res.data.length ? res.data[0].list : [],
        });
      });
    }
  }
  onSearch = value => {
    const { searchValueTab } = this.state
    if (searchValueTab == '商品') {
      let content = value
      this.props.history.push({ pathname: '/home/boutique/search', state: { content } })
      PubSub.publish('boutiqueSearch', content)
      this.setState({
        searchVal: value
      })
    } else if (searchValueTab == '商家') {
      let content = value
      this.props.history.push({
        pathname: '/home/nurseryShop',
        state: { content }
      })
      PubSub.publish('shop', content)
    } else {
      window.open(
        `${window.location.origin}/home/purchasinghall/${value}`,
        '_self'
      )
    }
  }
  handleChange = value => {
    if (value=='商家') {
      this.history(0)
    }else{
      this.history(1)
    }
    this.setState({
      searchValueTab: value
    })
  }

  sta = s => {
    const { tab } = this.state
    const tab1 = tab.map(a => {
      a.sta = false
      return a
    })
    tab1[s - 1].sta = true
    this.setState({
      tab: tab1
    })
  }
  // 购物车数量
  getShopNum = () => {
    if (!cookie.load('lj-pc-userInfo')) {
      const carArr = cookie.load('carArr')
      // console.log('carArr', carArr)
      const data = carArr || []
      if(data[0] && data[0].goodsList && data[0].goodsList.length) {
        const cartGoodsList = data.map(item => item.goodsList).reduce((acc, val) => acc.concat(val), [])
        this.setState({
          cartGoodsList,
          // cartGoodsNum: cartGoodsList.reduce((prev, cur) => cur.num + prev, 0)
        })
      }
    } else {
      const userInfo = cookie.load('lj-pc-userInfo')
      request('get', '/api/member/cart/list', {
        memberId: userInfo.memberId
      }).then(res => {
        // console.log('/list res --->', res)
        if (res.code === 0) {
          const data = res.data || []
          if(data[0] && data[0].goodsList && data[0].goodsList.length) {
            const cartGoodsList = data.map(item => item.goodsList).reduce((acc, val) => acc.concat(val), [])
            this.setState({
              cartGoodsList,
              // cartGoodsNum: cartGoodsList.reduce((prev, cur) => cur.num + prev, 0)
            })
          }
        }
      })
    }
  }
  jumpSearch(content) {
    if (this.state.searchValueTab=='商家') {
      PubSub.publish('shop', content)
      this.props.history.push({
        pathname: '/home/nurseryShop',
        state: { content }
      })
    }else{
      PubSub.publish('boutiqueSearch', content)
      this.props.history.push({ pathname: '/home/boutique/search', state: { content } })
    }

    this.setState({
      searchVal: content
    })
  }
  jumpUrl(id) {
    if (id == 6) {
      this.props.history.push({ pathname: '/home/Market' })
    }
  }
  jump() {
    this.props.history.push({ pathname: '/home/CouponList' })
  }
  // guanbi = () => {
  //   localStorage.setItem('youhuiIcon', 0)
  //   this.setState({
  //     youhuishou: 0
  //   })
  // }
  onRef = (ref) => {
    this.child = ref
  }
  goReserve = () => {
    if (!cookie.load('lj-pc-userInfo')) {
      this.child.isLoginModal()
    } else {
      // this.props.history.push({ pathname: '/home/rcserve' })
      window.open(`${location.origin}/home/rcserve`)
    }
  }
  // 购物车下拉菜单显示状态改变
  shopCartMenuChange = (visible) => {
    this.setState({
      hideBtnBorder: visible
    })
  }

  render() {
    const { historyList, tab, hideBtnBorder, searchValueTab, cartGoodsList } = this.state
    // 最后一个导航的下拉菜单
    const LastNavMenu = 
      <Menu>
        <Menu.Item key="0">
          <span onClick={() => this.goReserve()}>
            农技服务
          </span>
        </Menu.Item>
        <Menu.Item key="1">
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to="/home/MarketConsult/zczx"
          >
            政策资讯
          </Link>
        </Menu.Item >
        <Menu.Item key="2">
          <Link
            target='_blank'
            rel='noopener noreferrer'
            to='/home/agriculture'>
            农资商城
          </Link>
        </Menu.Item>
        <Menu.Item key="3">
          <Link target='_blank' rel='noopener noreferrer' to='/home/traceList'>
            溯源列表
          </Link>
        </Menu.Item>
        <Menu.Item key="4">
          <Link
            target='_blank'
            rel='noopener noreferrer'
            to='/Home/IndustryReports'>
            产业报告
          </Link>
        </Menu.Item>
        <Menu.Item key="5">
          <Link
            target='_blank'
            rel='noopener noreferrer'
            to='/home/RankingList'>
            排行榜
          </Link>
        </Menu.Item>
        <Menu.Item key="6">
          <Link
            target='_blank'
            rel='noopener noreferrer'
            to='/home/FinancialService'>
            金融服务
          </Link>
        </Menu.Item>
        <Menu.Item key="7">
          <Link
            target='_blank'
            rel='noopener noreferrer'
            to='/Home/AuctionService'>
            会销服务
          </Link>
        </Menu.Item>
      </Menu>
    
    // 购物车下拉菜单
    const ShopCartMenu = <>
      <div className='empty-box'>
        <div className='empty-box-left'></div>
        <Link 
          className='empty-box-right' 
          to='/myProfile/shoppingCart'
          target='_blank'
          rel="noopener noreferrer">
        </Link>
      </div>
      { cartGoodsList.length ? 
        <div className='shop-cart-content'>
          <ul className='goods-lists'>
          { cartGoodsList.map((item) => 
            <li key={item.goodsId} className='good-item'>
            <div className='item-l'>
              <img className='good-img' src={item.goodsImg} alt="商品图片" width={52} height={52} />
            </div>
            <div className='item-m line-clamp-3'>
              {item.goodsName}
            </div>
            <div className='item-r'>
              <div className='sub-total'>
                {`￥${splitDecimals(item.productPrice)[0]}${splitDecimals(item.productPrice)[1]}*${item.num}`}
              </div>
              <div className='total'>
                小计：<span className='total-num'>￥{(item.productPrice * item.num).toFixed(2)}</span>
              </div>
            </div>
          </li>
        )}
          </ul> 
          <div className='content-bottom'>
            <div className='goods-count'>
              共 <span className='font-bold'>
                  {/* {cartGoodsNum} */}
                  { cartGoodsList.length }
                </span> 种商品
            </div>
            <Link
              className='go-shopping-cart'
              to='/myProfile/shoppingCart'
              >
              去购物车
            </Link>
          </div>
      </div> : 
      <div className='shop-cart-content shop-cart-empty'>
        <Empty 
          image={Empty.PRESENTED_IMAGE_SIMPLE}  
        />
      </div>
      }
    </>

    return (
      <div className='header-wrapper'>
        <div className='header-center'>
          <div className='homeToplo'>
            <Link to='/home/index'>
              <img className='login' alt='' src={LogoImg} />
            </Link>
            <div className='hometop'>
              <div className='flex1'>
                <div className='flex1-left'>
                  <div className='header-select-input'>
                    {/* 下拉框 */}
                    <Select
                      popper-class='header-ant-select'
                      value={this.state.searchValueTab}
                      onChange={this.handleChange}>
                      <Option value='商品'>商品</Option>
                      <Option value='商家'>商家</Option>
                      {/* <Option value='采购'>采购</Option> */}
                    </Select>
                    {/* 搜索框 */}
                    <Search
                      className='header-input-group-wrapper'
                      placeholder={`请输入${searchValueTab === '采购' ? '采购商品' : searchValueTab}名称`}
                      onSearch={this.onSearch}
                      enterButton
                    />
                  </div>
                  <Dropdown 
                    overlay={ShopCartMenu} 
                    overlayClassName='shop-cart-menu'
                    overlayStyle={{
                      height: `${
                        cartGoodsList.length === 0 ? 145 : 
                        cartGoodsList.length <= 5 ? cartGoodsList.length * 72 + 87 : 455}px`
                    }}
                    placement='bottomRight' 
                    onVisibleChange={this.shopCartMenuChange}
                  >
                    <Link
                      to='/myProfile/shoppingCart'
                      target='_blank'
                      rel="noopener noreferrer"
                      className='shopping-cart'
                      style={{
                        border: hideBtnBorder ? '1px solid transparent' : '1px solid #f0f0f0'
                      }}
                      >
                      <Badge 
                        // count={cartGoodsNum}
                        count={cartGoodsList.length}
                        showZero 
                        className='cart-badge' 
                        offset={[8, 0]}
                      >
                        <img className='gou-icon' src={Gou} alt='' />
                      </Badge>
                      <span className='cart-text'>购物车</span>
                    </Link>
                  </Dropdown>
                </div>
                {/* <img className='flex1-right' style={{ width: 100, height: 100 }} src={window.location.protocol === 'http:' ? 'https://obs-ahhf.woyun.cn/guomiaodashuju/upload/IMG_400_400%4069ae7e2dca7f45fe91738c26d5909c4e.png' : 'https://obs-ahhf.woyun.cn/guomiaodashuju/upload/IMG_400_400%40cf8930fa81524f34b5974f7ff49265a2.png'} alt='二维码' /> */}
              </div>
              <div className='history-list'>
                {historyList &&
                  historyList.map((a, idx) => 
                  <Fragment key={idx}>
                   {a.content && 
                     <div
                      className={a.content ? 'history-item' : ''}
                      onClick={() => this.jumpSearch(a.content)}>
                      {a.content}
                    </div>}
                  </Fragment>
                )}
              </div>
            </div>
          </div>

          <div className='tabW'>
            <div className='tab'>
              {tab &&
                tab.map((a, idx) => (
                  <NavLink
                    exact
                    to={a.path}
                    key={idx}
                    activeClassName='sta'
                    className='item '>
                    {a.name}
                  </NavLink>
                ))}
              {/* <Dropdown
                width={200}
                overlayClassName='dropdown'
                overlay={LastNavMenu}
              >
                <span className='item dropdown-item'>服务中心</span>
              </Dropdown> */}
            </div>
          </div>
        </div>

        {/* {this.state.youhuishou != 0 && (
          <div>
            <img
              onClick={this.guanbi}
              className='youhuiIconX'
              src={youhuiquanX}
              alt=''
            />
            <img
              onClick={() => this.jump()}
              className='youhuiiicon'
              src={youhuiiicon}
              alt=''
            />
          </div>
        )} */}
        {/* 登录 */}
        {/* <LoginModal onRef={this.onRef} herf={'/home/rcserve'}></LoginModal> */}
      </div>
    )
  }
}
export default withRouter(Header)
