import React, { useEffect, useState } from 'react';
import './index.scss';
import request from '../../../../static/js/http';
import Gotoshop from '../../../../static/img/gotoshop.png'
// import KefuIcon from '@/static/img/new-ui/kefu-icon.png'
import cookie from 'react-cookies'
import { message } from 'antd'
import { Link } from 'react-router-dom';
// import LazyLoad from 'react-lazyload';
function App() {
  const [list, setList] = useState([]);
  const [getShopList, setGetShopList] = useState([]);
  // const [isScrolle, setIsScrolle] = useState(true);
  const getList = () => {
    request('post', '/api/apply/purchase/list', {
      pageNum: 1,
      pageSize: 11,
      statu: "0",
    }).then((res) => {
      if (res.code === 0) {
        setList(res.data.list);
      }
    });
  };
  const shopList = () => {
    request('post', '/api/goods/index/top3', {
      pageNum: 1,
      pageSize: 8,
    }).then((res) => {
      if (res.code === 0) {
        // console.log('res', res)
        setGetShopList(res.data.shopList);
      }
    });
  };
  useEffect(() => {
    getList();
  }, []);
  useEffect(() => {
    shopList();
  }, []);

  // 打开“聊一聊窗口”
  const openChatWindow = (event, id) => {
    event.preventDefault()
    if(!id) return
    if (!cookie.load('lj-pc-userInfo')) {
      message.warning('请登录')
    } else {
      const win = window.open('/home/Achat/' + id, 'newwindow', 'width=1200,height=820,left=200,top=123')
      win.focus()
    }
  }

  return (
    <div className="guangchangshang">
      <div className='newQiuGou' >     
        <div className="shopListTui">
          {/* 暂时截取六个 */}
          {
            getShopList.slice(0, 8).map((item) => (
              <Link target="_blank" className='shop-link' rel="noopener noreferrer" key={item.shopId} to={'/store/' + item.shopId}>
                <div className="shopItem">
                    {/* <LazyLoad height={200} offset={100}> */}
                      <img src={item.shopLogo} alt="" className='shop-cover' />
                    {/* </LazyLoad> */}

                  <div className='shop-name-wrapper'>
                    <div className="shopNames ellipsis" title= {item.shopName}>
                      <img src={Gotoshop} alt="" width={15} height={14} className='shop-icon' />
                      {item.shopName}
                    </div>
                    {/* <img src={KefuIcon} alt="聊一聊" width={15} height={14} className='kefu-icon' 
                        onClick={(e) => openChatWindow(e, item.shopPhone)} /> */}
                  </div>
                </div>
              </Link>
            ))
          }
        </div>
      </div>
    </div>
  );
}
export default App;