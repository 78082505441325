import React, { Component } from 'react';
import { Form, Input, Button, message } from 'antd';
import { MobileOutlined, MailOutlined, FileDoneOutlined } from '@ant-design/icons';
import loginlogo from '../../../../static/img/webljLogo.jpg';
import './index.scss';
import request from '../../../../static/js/http'
import cookie from 'react-cookies'

import { setRawCookie } from 'react-cookies';
export default class MobileLogin extends Component {
  constructor() {
    super();
    this.state = {
      phone: '',
      code: "",
      password: '',
      openid: '',
      btnText: '发送验证码',
      btnBool: false,
      ipo: ''
    };
  }

  componentDidMount() {
    ////console.log(this);
    // cookie.remove('lj-pc-userInfo')
    // cookie.remove('lj-pc-token')
    console.log('登录');
  }

  //注册
  reg = () => {
    // let userInfo = cookie.load('lj-pc-userInfo')
    ////console.log(userInfo);
    const { data, code } = this.state
    if ((this.state.phone && this.state.code) !== "") {
      // if (data == code) {
        request('post', '/api/member/code/login', {
          memberPhone: this.state.phone,
          code: this.state.code
        }).then(res => {
          let memberId = res.memberId
          if (res.code === 0) {
            // location.reload()
            let inFifteenMinute = new Date(new Date().getTime() + 24 * 3600 * 1000);//一天
            cookie.save('lj-pc-userInfo', res.data, { expires: inFifteenMinute, path: '/' })
            //   let inFifteenMinutes = new Date(new Date().getTime() + 24 * 3600 * 1000);//一天
            //   cookie.save('lj-pc-user', res.token, { expires: inFifteenMinutes })
            if (res.data.level==10) {
              this.props.history.push({ pathname: "/home/identity" })
            }else{
              this.props.history.push({ pathname: "/home/index" })
            }
            request('post', '/api/member/user/info', {
              memberId
            }).then(res => {
              if (res.code === 0) {
                let data = res.data
                let inFifteenMinute = new Date(new Date().getTime() + 168 * 3600 * 1000);//七天
                cookie.save('lj-webPc-data', data, { expires: inFifteenMinute, path: '/' })
                location.reload()
              }
            })
            // request('post', '/api/member/user/info', {
            //   memberId
            // }).then(res => {

            //   // if (res.code === 0) {
            //   //   if (res.data.auth == 0) {
            //   //     this.props.history.push({ pathname: "/home/identity" })
            //   //   } else {
            //   //     this.props.history.push({ pathname: "/home/index" })
            //   //   }
            //   // }
            // })
          } else {
            message.info(res.msg);
          }
        })
      // } else {
      //   message.info('请输入正确的验证码')
      // }
    }
  }



  onFinish = values => {
    // eslint-disable-next-line no-console
    ////console.log('Received values of form: ', values);
  };

  // 获取input的值 手机号码
  input(v) {
    this.setState({
      phone: v.target.value,
    });
    this.setState({
      ipo: v.target.value
    });
  }
  getcode(e) {
    this.setState({
      code: e.target.value
    });
  }

  sendCode() {
    // ////console.log(this.state.phone,"-------------------------");
    request("post", "/api/member/sms/send", {
      phone: this.state.phone
    }).then(res => {
      ////console.log(res);
      this.setState({
        data: res.data
      })
    })
    const { ipo } = this.state;
    const text = /^1\d{10}$/;
    if (!text.test(ipo)) {
      message.error('手机号格式错误');
    } else {
      let maxTime = 60;
      this.timer = setInterval(() => {
        if (maxTime > 0) {
          --maxTime;
          this.setState({
            btnText: `重新获取${maxTime}s`,
            btnBool: true
          });
        } else {
          this.setState({
            btnText: '发送验证码',
            btnBool: false
          });
        }
      }, 1000);
    }
  }

  render() {
    const { btnText, btnBool } = this.state;
    return (
      <Form
        name="normal_login1"
        className="login-form"
        autoComplete="off"
        initialValues={{
          remember: true
        }}
        size="large"
        onFinish={values => this.onFinish(values)}
        id="mobilelogin"
      >
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: '请输入手机号'
            }, {
              pattern: /^1\d{10}$/,
              message: '不合法的手机号格式!'
            }
          ]}
        >
          <Input
            autoComplete="off"
            onChange={v => this.input(v)}
            maxLength="11"
            type="number"
            prefix={<MobileOutlined className="site-form-item-icon" />}
            placeholder="请输入手机号"
          />
        </Form.Item>
        <Form.Item className="verification">
          <Form.Item
            name="password"
            className="yan"
            rules={[
              {
                required: true,
                message: '请输入验证码!'
              }, {
                pattern: /^\d{6}$/,
                message: '请输入正确的验证码!'
              }

            ]}
          >
            <Input
              prefix={<MailOutlined className="site-form-item-icon" />}
              autoComplete="off"
              type="number"
              onChange={e => this.getcode(e)}
              placeholder="请输入验证码!"
            />
          </Form.Item>
          <Button className="botton" onClick={() => this.sendCode()} type="primary" disabled={btnBool}>{btnText}</Button>
        </Form.Item>
        <Form.Item className="picver">
          {/* <Form.Item
            name="yan"
            className="yan"
            rules={[
              {
                required: true,
                message: '请输入右侧数字!'
              }
            ]}
          >
           
          </Form.Item> */}

        </Form.Item>

        <Form.Item>
          <Button type="primary" onClick={this.reg} htmlType="submit" className="login-form-button">
            登录
          </Button>
        </Form.Item>
      </Form>
    );
  }
}
