import React, { Component } from 'react';
import Carousel1 from '../../../components/Carousel1';
import Iiem from '../../../components/Item';
// import lun from '../../../static/img/del_home_lun.png';
import BackTop1 from '../../../components/BackTop1';
import { Link } from 'react-router-dom';
import request from '../../../static/js/http'
import GoodsTui from './goodsTui' // 商品推荐
import Guangchangshang from './guangchangshang'// 推荐店铺
import HomeRank from '../../Home/Index1/HomeRank/index'// 排行榜
import QinghuoAndXiangcun from './qinghuoAndXiangcun'// 清货专区、
// import cookie from 'react-cookies';
export default class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lunbo: [],
      wantedList: [],
      leaseList: [],
      merchants: [],
      shopId: ''
    };
  }
  componentDidMount() {
    this.lunbo()
    this.wantedList()
    this.leaseList()
    this.merchants()
  }

  wantedList() {
    request('post', '/api/storage/lease/list', {
      pageSize: 3,
      pageNum: 1,
      statu: 0
    }).then(res => {
      ////console.log(res);
      if (res.code == 0) {
        this.setState({
          wantedList: res.data
        })
      }
    })
  }
  leaseList() {
    request('post', '/api/storage/rent/list', {
      pageSize: 3,
      pageNum: 1,
      statu: 0
    }).then(res => {
      ////console.log(res);
      if (res.code == 0) {
        this.setState({
          leaseList: res.data
        })
      }
    })
  }

  lunbo() {
    request('post', '/api/cms/slide/list', {
      type: 1
    }).then(res => {
      this.setState({
        lunbo: res.data
      })
    })
  }
  //优选商家
  merchants() {
    // let memberId = cookie.load('lj-pc-userInfo').memberId&&''
    request('post', '/api/shop/shop/list', {
      memberId: '',
      pageNum: 1,
      pageSize: 10,
      recommend:1
    }).then(res => {
      ////console.log(res);
      this.setState({
        merchants: res.data.list
      })
    })
  }
  getShopId(shopId) {
    this.setState({
      shopId
    })
  }
  wanted(id) {
    this.props.history.push({ pathname: '/home/qiuzuxiangqing', state: { id } })
  }
  lease(id) {
    this.props.history.push({ pathname: '/home/chuzuxiangqing', state: { id } })
  }
  xiangqing(id) {
    this.props.history.push({ pathname: '/home/details', state: { id } })
  }
  render() {
    const { lunbo, wantedList, leaseList, merchants ,shopId} = this.state;
    ////console.log(shopId);
    ////console.log(leaseList);
    return (
      <div id='index' className='home-index'>
        <Carousel1 />
        <GoodsTui />
        <div className='love'>
          <div className='home-section-title'>
            <div className='text'>推荐店铺</div>
            <div className='more'>
              <Link target="_blank" to='/home/nurseryShop'>
                查看更多
              </Link>
            </div>
          </div>
          <Guangchangshang component={Guangchangshang} />
        </div>
        <section className='home-section'>
          <div className='home-section-title'>
            <div className='text'>排行榜</div>
            <div className='more'>
              <Link target="_blank" to='/home/RankingList'>
                查看更多
              </Link>
            </div>
          </div>
          <HomeRank />
        </section>
        {/* <QinghuoAndXiangcun component={QinghuoAndXiangcun} /> */}
        {/* <Guangchang component={Guangchang} /> */}
        <div className='love'>
          <div className='home-section-title'>猜您喜欢</div>
          <Iiem /> 
        </div>
        <BackTop1 />
      </div>
    );
  }
}
