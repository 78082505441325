import React, { Component } from 'react';
import { Carousel, Modal } from 'antd';
import request from '../../static/js/http'
import SecondLevel from '../secondlevel'
import Zixun from './zixun'
import './index.scss';
import motou from '../../static/img/motou.png'
import liulan from '../../static/img/liulan.png'
import wuliu from '../../static/img/wuliu.png'
import caigou from '../../static/img/caigou.png'
import mapicon from '../../static/img/mapicon.png'
// import erwei from '../../static/img/erwei.png'
import {
  RightOutlined,
  LeftOutlined
} from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';
import cookie from 'react-cookies';
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [1, 2, 3],
      lunbo: [],
      userInfo: [],
      orderNum: [],
      isModalVisible: false
    };
  }
  componentDidMount() {
    this.lunbo()
    if (cookie.load('lj-pc-userInfo')) {
      this.userInfo()
      this.orderNum()
    }

  }
  showModal = () => {
    // this.setState({
    //   isModalVisible: true
    // })
  };

  handleOk = () => {
    this.setState({
      isModalVisible: false
    })
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false
    })
  };
  lunbo() {
    request('post', '/api/cms/slide/list', {
      type: 1,
      dateType: 0,
      timestamp: new Date().getTime().toString(),
    }).then(res => {
      this.setState({
        lunbo: res.data.filter(item => !!item.slideImg)
        // lunbo: res.data
      })
    })
  }
  userInfo() {
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/member/user/info', {
      memberId,
      timestamp: new Date().getTime().toString(),

    }).then(res => {
      this.setState({
        userInfo: res.data,
      })
    })
  }
  orderNum() {
    request('post', '/api/order/order/num', {
      memberId: cookie.load('lj-pc-userInfo').memberId,
      timestamp: new Date().getTime().toString()
    }).then(res => {
      this.setState({
        orderNum: res.data
      })
    })
  }
  login() {
    const userInfo = this.state
    if (userInfo != '') {
      cookie.remove('lj-pc-userInfo', { path: '/' })
      cookie.remove('lj-webPc-data', { path: '/' })
      localStorage.removeItem('youhuiIcon')
      this.props.history.push({ pathname: '/login/mobile' })
    } else {
      this.props.history.push({ pathname: '/login/mobile' })
    }

  }
  wuliu() {
    this.props.history.push({ pathname: '/home/Cuohedating' })
  }
  caigou() {
    this.props.history.push({ pathname: '/home/purchasinghall/purchasinghall' })
  }
  map() {
    this.props.history.push({ pathname: '/home/nurseryMap' })
  }
  gongying() {
    this.props.history.push({ pathname: '/home/boutique/boutique' })

  }
  jump(slideMode, fileType, slideLink, fileId, slideId) {
    request('post', '/api/cms/slideViewCount', {
      slideId
    }).then(res => {
      if (slideMode == 1) {
        if (res.code == 0 || res.code == 200) {
          if (fileType == 0) {
            window.open(slideLink)
          } else if (fileType == 1) {
            let url = `/store/${fileId}?${slideId}`;
            window.open(url);
          } else if (fileType == 2) {
            let url = `/home/details/${fileId}?${slideId}`;
            window.open(url);
          } else {
            window.open(slideLink)
          }
        }
      }

    })
  }
  videoPlay = (e) => {
    // this.setState({
    //   // isVideoShow: true
    // }, function () {
    video.play();
    // })
  }
  render() {
    const { lunbo, userInfo, orderNum } = this.state;
    const contentStyle = {
      cursor: 'pointer',
      height: '456px',
      width: '721px',
      textAlign: 'center',
      backgroundSize: 'cover'
    };
    const lunboStyle = {
      width: '100%',
      height: '100%',
    }

    return (

      <div style={{ marginBottom: '30px' }} id="co">
        <SecondLevel />
        <div className='Carousela'>
          <div className="slider_control_prev" onClick={() => {
            this.refs.Carousel.prev();
          }}>
            <LeftOutlined />
          </div>
          <Carousel ref="Carousel" effect="fade" className='carousels' autoplay>
            {
              lunbo.map(item => {
                return <div key={item.slideId} style={'height:100%'}>
                  <div style={contentStyle}>
                    <div style={lunboStyle}>
                      {
                        item.fileType == 3 ? <video controls="controls" style={{ height: '456px' }} onClick={(e) => this.videoPlay(e)} src={item.slideImg}>
                          <source
                            src={item.slideImg}
                            type="video/mp4" />
                        </video> : ''
                      }
                      <img onClick={() => this.jump(item.slideMode, item.fileType, item.slideLink, item.fileId, item.slideId)} style={lunboStyle} src={item.slideImg} alt="" />
                    </div>
                  </div>
                </div>
              })
            }
          </Carousel >
          <div className="slider_control_next" onClick={() => {
            this.refs.Carousel.next();
          }}>
            <RightOutlined />
           
          </div>
        </div>
        <div id="zixun">
          <div className="backg"></div>
          <Zixun />
        </div>
        <div className="userinfo">
          <div className="touxiang">
            <img src={userInfo != '' ? userInfo.memberAvator : motou} alt="" />
          </div>
          <div className="userName">
            {userInfo != '' ? userInfo.memberName : 'HI，你好！'}
          </div>
          <div className="huanying">
            欢迎来到颍山红交易中心！
          </div>
          <div className="btn">
            <div className="login" onClick={() => this.login()}>
              {userInfo != '' ? '退出登录' : '免注册登录'}
            </div>
            <div className="xiazai" onClick={() => { this.login() }}>
            免登录注册
            </div>
          </div>

          {/* todo */}
          {userInfo && userInfo.length !== 0 ?
            <div className="wuliuNum">
              <Link to={{ pathname: '/myProfile/order', state: { type: 1 } }}>
                <div className="wuliuNums">
                  <div className="num">{userInfo != '' ? orderNum.dzf : '0'}</div>
                  <div className="text">待支付</div>
                </div>
              </Link>
              <Link to={{ pathname: '/myProfile/order', state: { type: 2 } }}>
                <div className="wuliuNums">
                  <div className="num">{userInfo != '' ? orderNum.dfh : '0'}</div>
                  <div className="text">待发货</div>
                </div>
              </Link>
              <Link to={{ pathname: '/myProfile/order', state: { type: 3 } }}>
                <div className="wuliuNums">
                  <div className="num">{userInfo != '' ? orderNum.dsh : '0'}</div>
                  <div className="text">待收货</div>
                </div>
              </Link>
              <Link to={{ pathname: '/myProfile/order', state: { type: 4 } }}>
                <div className="wuliuNums">
                  <div className="num">{userInfo != '' ? orderNum.dpj : '0'}</div>
                  <div className="text">待评价</div>
                </div>
              </Link>
            </div> : <div className='shopping-cart'></div>}

          <div className="mokuai">
            {/* <div onClick={() => this.gongying()} className="mokuais">
              <img src={liulan} alt="" />
              <div className="text">苗木浏览</div>
            </div>
            <div onClick={() => this.map()} className="mokuais">
              <img src={mapicon} alt="" />
              <div className="text">地图找苗</div>
            </div> */}
            <div onClick={() => this.caigou()} className="mokuais">
              <img src={caigou} alt="" />
              <div className="text">发布采购</div>
            </div>
            <div onClick={() => this.wuliu()} className="mokuais">
              <img src={wuliu} alt="" />
              <div className="text">物流中心</div>
            </div>
          </div>
        </div>
        <Modal className="xiazaia" footer={[]} visible={this.state.isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel}>
          <img src={window.location.protocol === 'http:' ? 'https://obs-ahhf.woyun.cn/guomiaodashuju/upload/IMG_400_400%4069ae7e2dca7f45fe91738c26d5909c4e.png' : 'https://obs-ahhf.woyun.cn/guomiaodashuju/upload/IMG_400_400%40cf8930fa81524f34b5974f7ff49265a2.png'} alt="" />
        </Modal>
      </div >
    );
  }
}
export default withRouter(Index)