import React, { Component } from 'react';
import './index.scss';
import { withRouter, Link } from 'react-router-dom';
import { Breadcrumb, Input, Modal, Button, Pagination, message } from 'antd';
import * as echarts from 'echarts';
import cookie from 'react-cookies';
import request from '../../../../static/js/http';
class FuturestradingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeData: [
        { name: '今日', id: '0' },
        { name: '近7天', id: '1' },
        { name: '近30天', id: '2' },
        { name: '季度', id: '3' },
        { name: '年度', id: '4' },
      ],
      currentIndex: 0,
      isBalance: false,   //显示余额不足model
      isSuccessfully: false,  //显示操作成功
      futureGoodsInfo: [],
      getGoodsList: [],
      money: 0,
      moneyStore: 0,
      productId: ''

    }
  }
  componentDidMount() {
    this.getGoodsList()
    this.futureGoodsInfo()
    if (cookie.load('lj-pc-userInfo')) {
      this.userInfo()
    }
  }
  futureGoodsInfo() {
    request('post', '/api/shop/future/goodsInfo', {
      productId: this.props.match.params.id
    }).then(res => {
      console.log(res);
      this.setState({
        futureGoodsInfo: res.data
      }, function (params) {
        this.lineChart()
      })
    })
  }
  //期货列表
  getGoodsList() {
    request('post', '/api/shop/future/goodsList', {
      classId: '',
      pageSize: 10,
      pageNum: 1
    }).then(res => {
      //console.log(res);
      if (res.code == 0) {
        this.setState({
          getGoodsList: res.data
        })
      }
    })
  }
  userInfo() {
    request('post', '/api/member/user/info', {
      memberId: !cookie.load('lj-pc-userInfo') ? '' : cookie.load('lj-pc-userInfo').memberId
    }).then(res => {
      this.setState({
        money: res.data.money
      })
    })
  }
  //点击选中timeItem改变样式
  setCurrentIndex(e) {
    this.setState({
      currentIndex: e
    })
  }
  //分页
  pagination = (a, b) => {
    ////console.log(a, b);
    request('post', '/api/shop/future/goodsList', {
      pageSize: b,
      pageNum: a,
      classId: ''
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          getGoodsList: res.data,
        })
      }
    })
  }
  //折线图
  lineChart() {
    const { futureGoodsInfo } = this.state
    //console.log(futureGoodsInfo);
    var chartDom = document.getElementById('main');
    var myChart = echarts.init(chartDom);
    var option;
    option = {
      xAxis: {
        type: 'category',
        data: futureGoodsInfo.contryWeekPrice && futureGoodsInfo.contryWeekPrice.map(item => {
          return item.dateStr
        })
      },
      yAxis: {
        type: 'value'
      },
      series: [{
        data: futureGoodsInfo.contryWeekPrice && futureGoodsInfo.contryWeekPrice.map(item => {
          //console.log(item.contryAvgPrice);
          return item.contryAvgPrice
        }),
        type: 'line'
      }]
    };
    option && myChart.setOption(option);
  }

  //买入
  futureGoodsBuy() {
    if (cookie.load('lj-pc-userInfo')) {
      const { productId, productPrice, futureGoodsInfo } = this.state
      //console.log(productId, productPrice);
      
      request('post', '/api/shop/future/goodsBuy', {
        productId: futureGoodsInfo.productId,
        money: futureGoodsInfo.allMoney + ''
      }).then(res => {
        //console.log(res);
        if (res.code == 0) {
          message.success(res.msg)
          this.userInfo()
          this.successfullyCancel()
        } else {
          message.warning(res.msg)
        }
      })
    } else {
      message.warning('请先登录')
    }

  }
  //余额不足
  showBalance = () => {
    this.setState({
      isBalance: true
    })
  };

  BalanceOk = () => {
    this.setState({
      isBalance: false
    })
  };

  BalanceCancel = () => {
    this.setState({
      isBalance: false
    })
  };

  showIsSuccessfully = (productId) => {
    console.log(productId);
    if (!this.state.futureGoodsInfo.productPrice) {
      message.warning('暂未设置价格，请先联系平台聊一聊')
    }else{
      this.setState({
        isSuccessfully: true,
        productId:productId?productId:this.props.match.params.id
      }, function () {
        request('post', '/api/shop/future/goodsInfo', {
          productId: this.state.productId
        }).then(res => {
          //console.log(res);
          this.setState({
            futureGoodsInfo: res.data
          })
        })
      })
    }
  };

  successfullyOk = () => {
    this.setState({
      isSuccessfully: false
    })
  };

  successfullyCancel = () => {
    this.setState({
      isSuccessfully: false
    })
  };
  render() {
    const { futureGoodsInfo, getGoodsList } = this.state
    let itemData = this.props.location.state.item
    console.log(itemData);
    return (
      <>
        <div className="breadcrumbW">
          <div className="breadcrumb">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>当前位置</Breadcrumb.Item>
              <Breadcrumb.Item href="/home/futurestrading">期货交易</Breadcrumb.Item>
              <Breadcrumb.Item>交易详情</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="futurestradingDetails">
          {/* <div className="futurestradingDetailsTop">
            <div className="contract">
              <span>合约号</span>
              <Input style={{ width: '120px', marginRight: '50px' }} placeholder="输入合约号" />
            </div>
            <div className="search"></div>
          </div> */}
          <div className="futurestradingDetailsBottom">
            <div className="futurestradingDetailsLeft">
              {/* <div className="timeList">
                {
                  timeData.map(item => {
                    return <div key={item.id} className="timeItem" id={`${item.id == this.state.currentIndex ? 'active' : ''}`} onClick={() => this.setCurrentIndex(item.id)}>
                      {item.name}
                    </div>
                  })
                }
              </div> */}
              {
                futureGoodsInfo && futureGoodsInfo.type == 1 ?
                  <div id="main" style={{ width: '100%', height: '100%' }}></div>
                  :
                  <>
                    <div>暂无信息</div>
                    <div id="main" style={{ width: '100%', height: '100%', display: 'none' }}></div>
                  </>
              }

            </div>
            <div className="futurestradingDetailsRight">
              <div className="futurestradingDetailsRightTop">
                {/* {
                  futureGoodsInfo && futureGoodsInfo.type == 1 ? */}
                    <>
                      <div className="title">
                        {futureGoodsInfo && futureGoodsInfo.className} {futureGoodsInfo && futureGoodsInfo.goodsProp}
                      </div>
                      <div className="content">
                        {/* <div className="contentItem">
                    <div className="name">合约号</div>
                    <div className="value">21323</div>
                  </div> */}
                        <div className="contentItem">
                          <div className="name">涨幅</div>
                          <div className="value">{futureGoodsInfo.contryAvgPriceGrowth||0}%</div>
                        </div>
                        <div className="contentItem">
                          <div className="name">卖价</div>
                          <div className="value">{futureGoodsInfo.productPrice}</div>
                        </div>
                        <div className="contentItem">
                          <div className="name">市场价</div>
                          <div className="value">{futureGoodsInfo.contryAvgPrice||0}</div>
                        </div>
                        <div className="contentItem">
                          <div className="name">最高价格</div>
                          <div className="value">{futureGoodsInfo.contryMaxPrice||0}</div>
                        </div>
                        {/* <div className="contentItem">
                    <div className="name">最低价格</div>
                    <div className="value">2400.00</div>
                  </div> */}
                      </div>
                      <div className="buyBtn" onClick={() => this.showIsSuccessfully()}>
                        买入
                      </div>
                    </> 
                    {/* : */}
                    {/* <div>暂无信息</div> */}
                {/* } */}
              </div>
              <div className="futurestradingDetailsRightBottom">
                <div className="futurestradingDetailsRightBottomTable">
                  <table className="listTable">
                    <thead className="listThead">
                      <tr className="listTr">
                        <th>名称</th>
                        <th>等级</th>
                        <th>最新市场价</th>
                        <th>涨幅</th>
                        <th className="tabOperationTh">操作</th>
                      </tr>
                    </thead>
                    <tbody className="listTbody">
                      {
                        getGoodsList.list && getGoodsList.list.map(item => {
                          return <tr key={item.productId} className="TbodyTr" >
                            <td>{item.className}</td>
                            <td>{item.goodsProp}</td>
                            <td>{item.contryAvgPrice}</td>
                            <td>{item.contryAvgPriceGrowth}%</td>
                            <td className="tabOperationTd">
                              <span onClick={() => this.showIsSuccessfully(item.productId)}>买入</span>
                              <Link target="_blank" to={'/home/futurestradingDetails/' + item.productId}>
                                <span >查看</span>
                              </Link>
                            </td>
                          </tr>
                        })
                      }

                    </tbody>
                  </table>
                </div>
                <div className="paging">
                  <Pagination style={{ marginBottom: '57px' }} pageSize='10' current={getGoodsList.pageNum} onChange={this.pagination} defaultCurrent={1} total={getGoodsList.total} />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 买入余额不足 */}
        <Modal className="balanceNoModal" footer={[]} title="提示" visible={this.state.isBalance} onOk={this.BalanceOk} onCancel={this.BalanceCancel}>
          <p>账户余额不足，是否前往充值？</p>
          <div className="balanceNoModalBtn">
            <div className="borrowMoney">
              去借款
            </div>
            <div className="topUp">
              去充值
            </div>
          </div>
        </Modal>
        <Modal title={`可用余额：${this.state.money}元`} footer={[]} className="detailsBalanceModal" visible={this.state.isSuccessfully} onOk={this.successfullyOk} onCancel={this.successfullyCancel}>
          <div className="addOrderModalContent">
            <table className="listTable">
              <thead className="listThead">
                <tr className="listTr">
                  <th>合约号</th>
                  <th>品种</th>
                  <th>等级</th>
                  <th>数量(斤）</th>
                  <th>单价</th>
                  <th>合计</th>
                </tr>
              </thead>
              <tbody className="listTbody">
                <tr className="TbodyTr" >
                  <td>{futureGoodsInfo.sku}</td>
                  <td>{futureGoodsInfo.className}</td>
                  <td>{futureGoodsInfo.goodsProp}</td>
                  <td>{futureGoodsInfo.productWeight * futureGoodsInfo.productStock}</td>
                  <td>{futureGoodsInfo.productPrice}</td>
                  <td>{futureGoodsInfo.moneyGoods}</td>
                </tr>
              </tbody>
            </table>
            <div className="summation">
              <div className="summationLeft">
                <div className="goodsPrice"><span className="goodsPriceName">商品费用：</span> <span className="goodsPriceValue">{futureGoodsInfo.moneyGoods}</span> </div>
                <div className="rent"><span className="rentName">租金：</span> <span className="rentValue">{futureGoodsInfo.moneyStore}</span> </div>
                <div className="unit">（每吨  {futureGoodsInfo.moneyStore}元/年）</div>
              </div>
              <div className="summationRight">
                <span>实付：</span><span className="Price">{futureGoodsInfo.allMoney}</span>
              </div>
            </div>
            <div className="addOrderModalContentBottom" onClick={() => this.futureGoodsBuy()}>
              <span>确认下单</span>
            </div>
          </div>
        </Modal>
        {/* 买入成功 */}
        {/* <Modal className="successfullyModal" footer={[]} title="操作确认" visible={this.state.isSuccessfully} onOk={this.successfullyOk} onCancel={this.successfullyCancel}>
          <p>山樱椒，买入，5892元，1手</p>
          <div className="successfullyModalBtn">
            <Button type="primary" onClick={this.successfullyOk}>确认</Button>

            <div className="cancel" onClick={this.successfullyCancel}>
              取消
            </div>
            <div className="topUp">
              去充值
            </div>
          </div>
        </Modal> */}
      </>
    )
  }
}

export default withRouter(FuturestradingDetails)