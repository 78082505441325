import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { UserOutlined, TabletOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { withRouter } from "react-router-dom"
import cookie, { load, remove } from 'react-cookies'
import './index.scss';
// import img from '../../static/img/del_home_lun.png';
import img from '../../static/img/erwei.jpg';
import request from '../../static/js/http';
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shopAuth: [],
    };
  }
  componentDidMount() {
  }
  quit=()=>{
    cookie.remove('lj-pc-userInfo',{path:'/'})
    cookie.remove('lj-webPc-data',{path:'/'})
    this.props.history.push({ pathname: '/login/mobile' })
    // location.reload()
  }
  


  render() {
    return (
      <div className="outer">
        <div className="interior">
          <div className="left">
            <Link to="/home">
              {/* 欢迎来到辣椒开放式物流线上电子交易中心 */}
            </Link>
          </div>
          <div className="right">
            <div>
              <Link to={!cookie.load('lj-pc-userInfo')?'/login/mobile':'/my/index'}>
                <div><UserOutlined className="iconuser" />{!cookie.load('lj-webPc-data') ? '免注册登录' : cookie.load('lj-webPc-data').memberName}</div>
              </Link>
            </div>
            {
              !cookie.load('lj-pc-userInfo') ? '' : <div>
                <div onClick={()=>this.quit()}>
                  退出登陆
                </div>
              </div>
            }

            <div>
              <Link to="/my">
                <div>个人中心</div>
              </Link>
            </div>
            <div>
              <Link to={!cookie.load('lj-webPc-data')?'/authentication':(cookie.load('lj-webPc-data').auth==0?'/authentication':'/my/renzheng')}>
                <div>{!cookie.load('lj-webPc-data')?'我要认证':(cookie.load('lj-webPc-data').auth==1?'已认证':'我要认证')}</div>
              </Link>
            </div>
            <div><div> <a href="http://shop.yingshanhongcn.com/">卖家后台</a> </div></div>
            <div>
              <Dropdown overlay={<img src={img} alt="下载二维码" className="eee" />} placement="bottomCenter" arrow>
                <div><TabletOutlined className="iconipo" />手机端</div>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(index)