import React, { Component } from 'react';
import './index.scss';
import { Radio, Button, Modal, message } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import w1 from '../../static/img/w1.png';
import w2 from '../../static/img/w2.png';
import w3 from '../../static/img/w3.png';
import { withRouter } from "react-router-dom"
import request from '../../static/js/http';
import cookie from 'react-cookies';
import QRCode from 'qrcode.react';
class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 2,
      orderinfo: [],
      orderAddress: [],
      detailList: [],
      codeUrl: '',
      isModalVisible: false
    };
  }
  componentDidMount() {
    if (!this.props.location.state.data) {
      this.orderinfo()
    }
  }

  //订单详情
  orderinfo() {
    console.log(this.props);
    // .orderId
    let orderId = !this.props.location.state.data ? this.props.location.state.orderId : this.props.location.state.data.orderId
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/order/order/info', {
      memberId,
      orderId,
    }).then(res => {
      //console.log(res);
      this.setState({
        orderinfo: res.data,
        orderAddress: res.data.orderAddress,
        detailList: res.data.detailList
      })
    })
  }
  orderList() {
    this.props.history.push({ pathname: '/myProfile/order' })
  }

  quzhifu = () => {
    let orderId = !this.props.location.state.data ? this.props.location.state.orderId : this.props.location.state.data.orderId
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/order/order/aliPayPc', {
      orderId,
      memberId
    }).then(res => {
      console.log(res);
      const newWindow = window.open("", "_self");
      newWindow.document.write(res);
    })
  }
  wx = () => {
    let orderId = !this.props.location.state.data ? this.props.location.state.orderId : this.props.location.state.data.orderId
    request('post', '/api/order/order/wxPcPay', {
      orderId,
    }).then(res => {
      if (res.code == 0) {
        this.setState({
          codeUrl: res.data.codeUrl
        })
      } else {
        message.warning(res.msg)
      }

      this.showModal()
    })
  }
  //已完成
  wc() {
    let orderId = !this.props.location.state.data ? this.props.location.state.orderId : this.props.location.state.data.orderId
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/order/order/info', {
      memberId,
      orderId,
    }).then(res => {
      console.log(res);
      if (res.code==500) {
        this.props.history.push({ pathname: '/myProfile/order', state: { type: 2 } })
      } else {
        if (res.data.status == 1) {
          message.warning('未完成支付')
        } else {
          this.props.history.push({ pathname: '/myProfile/orderdetails', state: { orderId } })
        }
      }

    })
  }
  onChange = e => {
    this.setState({
      value: e.target.value
    });
  };
  showModal = () => {
    this.setState({
      isModalVisible: true
    })
  };

  handleOk = () => {
    this.setState({
      isModalVisible: false
    }, function () {
      this.wc()
    })
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false
    })
  };
  render() {
    const { value, orderinfo, orderAddress, detailList, codeUrl } = this.state;
    const data = this.props.location.state.data
    const gouwu = this.props.location.state.gouwu
    const receipt = this.props.location.state.receipt
    const radioStyle = {
      height: '50px',
      lineHeight: '50px',
      width: '100%',
      marginBottom: '10px',
      padding: '0 20px',
      display: 'flex',
      alignItems: 'center'
    };
    return (
      <div className="payment">
        <div className="top">
          <CheckCircleFilled style={{ fontSize: '42px', color: '#C33529' }} />
          <div className="right">
            <div className="on1">
              订单提交成功，现在只差一步了
            </div>
            <div className="on2">
              请您在提交订单后<span>24小时00分内</span>完成支付，否则订单会自动取消！
            </div>
            <div className="hr" />
            <div className="right2">
              <div>
                <div>订单编号：{!data ? orderinfo.orderSn : data.orderSn}</div>
                <div>商品名称：
                  {!gouwu ?
                    detailList && detailList.map(item => {
                      return <span key={item.detailId}> {item.goodsName}</span>
                    })
                    : gouwu.data && gouwu.data.map(item => {
                      return <>
                        {
                          item.goodsList.map(it => {
                            return <span key={it.goodsId}> {it.goodsName}</span>
                          })
                        }
                      </>
                      // return <span key={item.detailId}> {item.goodsName}</span>
                    })
                  }
                  {
                    !receipt ?
                      <>
                        <span className="left2">{orderinfo.shopName}</span>
                        <div>收货信息：{orderAddress.receiverProvince}{orderAddress.receiverCity}{orderAddress.receiverArea}{orderAddress.receiverTown}，{orderAddress.receiverName}，手机号：{orderAddress.receiverPhone}</div>
                      </>
                      :
                      <>
                        {/* <span className="left2">
                          {
                            gouwu.data && gouwu.data.map(item => {
                              return item.shopName 
                            })
                          }
                        </span> */}
                        <div>收货信息：{receipt.province}{receipt.city}{receipt.area}{receipt.address}，{receipt.fullName}，手机号：{receipt.phone}</div>
                      </>

                  }
                </div>
              </div>
              <div onClick={() => this.orderList()} style={{ cursor: 'pointer' }}>我的订单 &gt;</div>
            </div>
          </div>
        </div>
        <div className="top2">
          <div className="on1">
            <span>支付金额</span>
            <span>￥{!data ? orderinfo.payment : data.payment}</span>
          </div>
          <div className="on2">
            <Radio.Group onChange={this.onChange} value={value}>
              <Radio style={radioStyle} value={1}>
                <div>
                  <img className="img1" src={w1} alt="微信" />
                  <span>微信支付</span>
                </div>
              </Radio>
              <Radio style={radioStyle} value={2}>
                <div>
                  <img className="img1" src={w2} alt="支付宝" />
                  <span>支付宝支付</span>
                </div>
              </Radio>
              <Radio style={radioStyle} value={3}>
                <div>
                  <img className="img1" src={w3} alt="拆分" />
                  <span>拆分支付</span>
                </div>
              </Radio>
            </Radio.Group>
          </div>
          <Button className="botton1" type="primary" onClick={() => `${this.state.value == 1 ? this.wx() : this.quzhifu()}`}>去支付</Button>
        </div>
        <Modal okText='已支付' cancelText='未支付' className="QRCodeModal" title="微信支付" visible={this.state.isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel}>
          <QRCode
            value={codeUrl && codeUrl}  //value参数为生成二维码的链接
            size={264} //二维码的宽高尺寸
            fgColor="#000000"  //二维码的颜色
          />
          <div className="wx_msgDiv">请使用 <span className="wx_msgSpan">微信</span> 扫一扫</div>
          <div>二维码完成支付</div>
        </Modal>

      </div>

    );
  }
}
export default withRouter(Payment)