import React, { Component } from 'react';
import './index.scss';
import { Input, Select, Modal, Button, message, Pagination } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import Carousel1 from '../../../components/Carousel1';
import {
  CaretUpOutlined,
  CaretDownOutlined
} from '@ant-design/icons';
import cookie from 'react-cookies';
import request from '../../../static/js/http';
import search from '../../../static/img/search.png'
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
const { Option } = Select;
// 过滤掉所有<i>元素
function filter(node) {
  return (node.tagName !== 'i');
}
class Futurestrading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionList: [
        { name: '持仓', id: 0 },
        { name: '委托', id: 1 },
        { name: '卖出记录', id: 2 },
        { name: '买入记录', id: 3 },
      ],
      breedList: [],      //品种列表
      breedName: '',       //获取当前选择的品种值
      classValue: '',       //获取当前选择的等级值
      futurestradingList: [],     //获取合约列表
      fenye: [],             //分页
      isShowBalance: false,   //卖出弹窗显示
      isBalance: false,   //显示余额不足model
      isSuccessfully: false,  //显示操作成功
      isChat: false,   //显示聊一聊谈窗
      isBalanceTab: false,   //点击显示卖出弹窗
      isBuying: false,   //点击显示买入弹窗
      isTiHuo: false,
      currentIndex: 0,
      getGoodsList: [],
      futureMyList: [],
      productId: '',
      unitPriceVla: '',
      productPrice: '',
      classId: '',
      productAllMoney: '',
      productStock: '',
      money: 0,
      moneyStore: 0,
      itemData: [],
      changeId: '',
      futurePickup: [],
      goodsInfo: [],
      numSort: '1',
      priceSort: '3',
      shiChangSort: '5',
      zhangSort: '7',
      buySort: '9',
      upNumSort: true,
      downSort: true,
      uppriceSort: true,
      downpriceSort: true,
      upshiChangSort: true,
      downshiChangSort: true,
      upzhangSort: true,
      downzhangSort: true,
      upNumbuySort: true,
      downbuySort: true,
      numColor: '',
      priceColor: '',
      shiChangColor: '',
      zhangColor: '',
      buyColor: '',

    }
  }
  componentDidMount() {
    this.breedList()
    this.getGoodsList()
    if (cookie.load('lj-pc-userInfo')) {
      this.userInfo()
    }

  }
  //期货列表
  getGoodsList() {
    request('post', '/api/shop/future/goodsList', {
      classId: '',
      pageSize: 10,
      pageNum: 1
    }).then(res => {
      //console.log(res);
      if (res.code == 0) {
        this.setState({
          getGoodsList: res.data
        })
      }
    })
  }
  //我的持仓/委托列表
  futureMyList() {
    const { currentIndex } = this.state
    request('post', '/api/shop/future/myList', {
      classId: '',
      pageNum: 1,
      pageSize: 6,
      type: currentIndex
    }).then(res => {
      //console.log(res);
      this.setState({
        futureMyList: res.data
      })
    })
  }

  //撤销委托
  futureGoodsCancel(productId) {
    request('post', '/api/shop/future/goodsCancel', {
      productId
    }).then(res => {
      //console.log(res);
      if (res.code == 0) {
        message.success(res.msg)
      } else {
        message.warning(res.msg)
      }
      this.futureMyList()
      this.getGoodsList()
    })
  }
  //辣椒委托(上架)
  futureGoodsSell() {
    const { productId, unitPriceVla } = this.state
    if (unitPriceVla == '') {
      message.warning('请填写单价(元)')
    } else {
      request('post', '/api/shop/future/goodsSell', {
        productId,
        price: unitPriceVla
      }).then(res => {
        //console.log(res);
        if (res.code == 0) {
          message.success(res.msg)
          this.setState({
            isShowBalance: false
          })
        } else {
          message.warning(res.msg)
        }
        this.futureMyList()
        this.getGoodsList()
      })
    }
  }
  //买入
  futureGoodsBuy() {
    const { productId, itemData, goodsInfo } = this.state
    //console.log(productId, productPrice);
    request('post', '/api/shop/future/goodsBuy', {
      productId: goodsInfo.productId,
      money: goodsInfo.productPrice + ''
    }).then(res => {
      //console.log(res);
      if (res.code == 0) {
        message.success(res.msg)
        this.userInfo()
        this.buyingCancel()
      } else {
        message.warning(res.msg)
      }
    })
  }

  //品种类型
  breedList() {
    request('post', '/api/goods/class/sList', {
      classId: 1,
    }).then(res => {
      //console.log(res);
      this.setState({
        breedList: res.data
      })
    })
  }
  //品种下拉值
  breedMenu(e) {
    //console.log(e);
    this.setState({
      breedName: e
    }, function () {
      this.breed()
      //console.log(e);
    })
  }
  //品种选择
  breed() {
    const { breedName } = this.state
    console.log(breedName);
    request('post', '/api/shop/future/goodsList', {
      pageSize: 10,
      pageNum: 1,
      classId: this.state.breedName ? this.state.breedName : ''
    }).then(res => {
      //console.log(res);
      if (res.code === 0) {
        this.setState({
          getGoodsList: res.data,
        })
      }
    })
  }
  //等级下拉值
  classMenu(e) {
    this.setState({
      classValue: e
    }, function () {
      //console.log(e);
      this.classxuanze()
    })
  }

  //等级选择
  classxuanze() {
    const { classValue } = this.state
    console.log(classValue);
    request('post', '/api/shop/future/goodsList', {
      classId: this.state.breedName,
      pageSize: 10,
      pageNum: 1,
      goodsProp: this.state.classValue
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          getGoodsList: res.data,
        })
      }
    })
  }
  //数量升降
  numSort() {
    const { numSort } = this.state
    if (numSort == 1) {
      this.setState({
        numSort: 2,
        upNumSort: true,
        uppriceSort: true,
        downpriceSort: true,
        upshiChangSort: true,
        downshiChangSort: true,
        upzhangSort: true,
        downzhangSort: true,
        upNumbuySort: true,
        downbuySort: true,
        downSort: false
      }, function () {
        // this.buyC()
        // this.zhangC()
        // this.shiChangC()
        // this.priceC()
        this.num()
      })
    } else {
      this.setState({
        numSort: 1,
        upNumSort: false,
        downSort: true,
        uppriceSort: true,
        downpriceSort: true,
        upshiChangSort: true,
        downshiChangSort: true,
        upzhangSort: true,
        downzhangSort: true,
        upNumbuySort: true,
        downbuySort: true,
      }, function () {
        // this.buyC()
        // this.zhangC()
        // this.shiChangC()
        // this.priceC()
        this.num()
      })
    }
  }
  num() {
    const { uppriceSort, downpriceSort, upshiChangSort, downshiChangSort, upzhangSort, downzhangSort, upNumbuySort, downbuySort } = this.state
    if (this.state.uppriceSort && this.state.downpriceSort && this.state.upshiChangSort && this.state.downshiChangSort && this.state.upzhangSort &&
      downzhangSort && upNumbuySort && downbuySort
    ) {
      console.log(1);
      this.setState({
        numColor: 'red',
        priceColor: '#000',
        shiChangColor: '#000',
        zhangColor: '#000',
        buyColor: '#000',
      })
    } else {
      console.log(2);
      this.setState({
        priceColor: '#000',
        shiChangColor: '#000',
        zhangColor: '#000',
        buyColor: '#000',
      })
    }
    request('post', '/api/shop/future/goodsList', {
      classId: this.state.breedName,
      pageSize: 10,
      pageNum: 1,
      sortType1: this.state.numSort
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          getGoodsList: res.data,
        })
      }
    })
  }
  //单价升降
  priceSort() {
    const { priceSort } = this.state
    if (priceSort == 3) {
      this.setState({
        priceSort: 4,
        uppriceSort: true,
        downpriceSort: false,
        upNumSort: true,
        downSort: true,
        upshiChangSort: true,
        downshiChangSort: true,
        upzhangSort: true,
        downzhangSort: true,
        upNumbuySort: true,
        downbuySort: true,
      }, function () {
        // this.buyC()
        // this.zhangC()
        // this.shiChangC()
        this.priceC()
        // this.num()
      })
    } else {
      this.setState({
        priceSort: 3,
        uppriceSort: false,
        downpriceSort: true,
        upNumSort: true,
        downSort: true,
        upshiChangSort: true,
        downshiChangSort: true,
        upzhangSort: true,
        downzhangSort: true,
        upNumbuySort: true,
        downbuySort: true,
      }, function () {
        // this.buyC()
        // this.zhangC()
        // this.shiChangC()
        this.priceC()
        // this.num()
      })
    }
  }
  priceC() {
    const { upNumSort, downSort, upshiChangSort, downshiChangSort, upzhangSort, downzhangSort, upNumbuySort, downbuySort } = this.state
    if (upNumSort && downSort && upshiChangSort && downshiChangSort && upzhangSort && downzhangSort && upNumbuySort && downbuySort
    ) {
      console.log(1);
      this.setState({
        priceColor: 'red',
        numColor: '#000',
        shiChangColor: '#000',
        zhangColor: '#000',
        buyColor: '#000',
      })
    } else {
      console.log(2);
      this.setState({
        priceColor: '#000',
      })
    }
    request('post', '/api/shop/future/goodsList', {
      classId: this.state.breedName,
      pageSize: 10,
      pageNum: 1,
      sortType2: this.state.priceSort
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          getGoodsList: res.data,
        })
      }
    })
  }

  //市场升降
  shiChangSort() {
    const { shiChangSort } = this.state
    if (shiChangSort == 5) {
      this.setState({
        shiChangSort: 6,
        upshiChangSort: true,
        downshiChangSort: false,
        upNumSort: true,
        downSort: true,
        uppriceSort: true,
        downpriceSort: true,
        upzhangSort: true,
        downzhangSort: true,
        upNumbuySort: true,
        downbuySort: true,
      }, function () {
        // this.buyC()
        // this.zhangC()
        this.shiChangC()
        // this.priceC()
        // this.num()
      })
    } else {
      this.setState({
        shiChangSort: 5,
        upshiChangSort: false,
        downshiChangSort: true,
        upNumSort: true,
        downSort: true,
        uppriceSort: true,
        downpriceSort: true,
        upzhangSort: true,
        downzhangSort: true,
        upNumbuySort: true,
        downbuySort: true,
      }, function () {
        // this.buyC()
        // this.zhangC()
        this.shiChangC()
        // this.priceC()
        // this.num()
      })
    }
  }
  shiChangC() {
    const { upNumSort, downSort, uppriceSort, downpriceSort, upzhangSort, downzhangSort, upNumbuySort, downbuySort } = this.state
    if (upNumSort && downSort && uppriceSort && downpriceSort && upzhangSort && downzhangSort && upNumbuySort && downbuySort
    ) {
      console.log(1);
      this.setState({
        shiChangColor: 'red',
        numColor: '#000',
        priceColor: '#000',
        zhangColor: '#000',
        buyColor: '#000',
      })
    } else {
      console.log(2);
      this.setState({
        shiChangColor: '#000',
      })
    }
    request('post', '/api/shop/future/goodsList', {
      classId: this.state.breedName,
      pageSize: 10,
      pageNum: 1,
      sortType3: this.state.shiChangSort
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          getGoodsList: res.data,
        })
      }
    })
  }
  //涨幅升降
  zhangSort() {
    const { zhangSort } = this.state
    if (zhangSort == 7) {
      this.setState({
        zhangSort: 8,
        upzhangSort: true,
        downzhangSort: false,
        upNumSort: true,
        downSort: true,
        uppriceSort: true,
        downpriceSort: true,
        upshiChangSort: true,
        downshiChangSort: true,
        upNumbuySort: true,
        downbuySort: true,
      }, function () {
        // this.buyC()
        this.zhangC()
        // this.shiChangC()
        // this.priceC()
        // this.num()
      })
    } else {
      this.setState({
        zhangSort: 7,
        upzhangSort: false,
        downzhangSort: true,
        upNumSort: true,
        downSort: true,
        uppriceSort: true,
        downpriceSort: true,
        upshiChangSort: true,
        downshiChangSort: true,
        upNumbuySort: true,
        downbuySort: true,
      }, function () {
        // this.buyC()
        this.zhangC()
        // this.shiChangC()
        // this.priceC()
        // this.num()
      })
    }
  }
  zhangC() {
    const { upNumSort, downSort, uppriceSort, downpriceSort, upshiChangSort, downshiChangSort, upNumbuySort, downbuySort } = this.state
    if (upNumSort && downSort && uppriceSort && downpriceSort && upshiChangSort && downshiChangSort && upNumbuySort && downbuySort
    ) {
      console.log(1);
      this.setState({
        zhangColor: 'red',
        numColor: '#000',
        priceColor: '#000',
        shiChangColor: '#000',
        buyColor: '#000',
      })
    } else {
      console.log(2);
      this.setState({
        zhangColor: '#000',
      })
    }
    request('post', '/api/shop/future/goodsList', {
      classId: this.state.breedName,
      pageSize: 10,
      pageNum: 1,
      sortType4: this.state.zhangSort
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          getGoodsList: res.data,
        })
      }
    })
  }
  //卖价升降
  buySort() {
    const { buySort } = this.state
    if (buySort == 9) {
      this.setState({
        buySort: 10,
        upNumbuySort: true,
        downbuySort: false,
        upNumSort: true,
        downSort: true,
        uppriceSort: true,
        downpriceSort: true,
        upshiChangSort: true,
        downshiChangSort: true,
        upzhangSort: true,
        downzhangSort: true,
      }, function () {
        this.buyC()
        // this.zhangC()
        // this.shiChangC()
        // this.priceC()
        // this.num()
      })
    } else {
      this.setState({
        buySort: 9,
        upNumbuySort: false,
        downbuySort: true,
        upNumSort: true,
        downSort: true,
        uppriceSort: true,
        downpriceSort: true,
        upshiChangSort: true,
        downshiChangSort: true,
        upzhangSort: true,
        downzhangSort: true,
      }, function () {
        this.buyC()
        // this.zhangC()
        // this.shiChangC()
        // this.priceC()
        // this.num()
      })
    }
  }
  buyC() {
    const { upNumSort, downSort, uppriceSort, downpriceSort, upshiChangSort, downshiChangSort, upzhangSort, downzhangSort } = this.state
    if (upNumSort && downSort && uppriceSort && downpriceSort && upshiChangSort && downshiChangSort && upzhangSort && downzhangSort
    ) {
      console.log(1);
      this.setState({
        buyColor: 'red',
        numColor: '#000',
        priceColor: '#000',
        shiChangColor: '#000',
        zhangColor: '#000',
      })
    } else {
      console.log(2);
      this.setState({
        buyColor: '#000',
      })
    }
    request('post', '/api/shop/future/goodsList', {
      classId: this.state.breedName,
      pageSize: 10,
      pageNum: 1,
      sortType5: this.state.buySort
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          getGoodsList: res.data,
        })
      }
    })
  }
  //点击选中transactionItem改变样式
  setCurrentIndex(e) {
    this.setState({
      currentIndex: e
    }, function (params) {
      this.futureMyList(e)
    })
  }
  unitPriceVla(e) {
    this.setState({
      unitPriceVla: e.target.value
    })
  }

  //分页
  pagination = (a, b) => {
    ////console.log(a, b);
    request('post', '/api/shop/future/goodsList', {
      pageSize: b,
      pageNum: a,
      classId: this.state.breedName,
      goodsProp: this.state.classValue,
      sortType1: (this.state.numSort) == 'string' ? '' : this.state.numSort,
      sortType2: typeof (this.state.priceSort) == 'string' ? '' : this.state.priceSort,
      sortType3: typeof (this.state.shiChangSort) == 'string' ? '' : this.state.shiChangSort,
      sortType4: typeof (this.state.zhangSort) == 'string' ? '' : this.state.zhangSort,
      sortType5: typeof (this.state.buySort) == 'string' ? '' : this.state.buySort,
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          getGoodsList: res.data,
        })
      }
    })
  }
  //持仓分页
  positionPagination = (a, b) => {
    console.log(a, b);
    const { currentIndex } = this.state
    console.log(currentIndex);
    request('post', '/api/shop/future/myList', {
      pageSize: b,
      pageNum: a,
      type: currentIndex,
      classId: ''
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          futureMyList: res.data,
        })
      }
    })
  }
  zhiNeng() {
    request('post', '/api/goods/video/info', {
      goodsId: ''
    }).then(res => {
      window.open('http://zhibo.yingshanhongcn.com/?id=' + res.data.url)
    })

  }
  //卖出里面的持仓里面的卖出的窗口
  showBalanceModal = (productId, productStock) => {
    this.setState({
      isShowBalance: true,
      productId,
      productStock
    })
  };
  //
  userInfo() {
    request('post', '/api/member/user/info', {
      memberId: !cookie.load('lj-pc-userInfo') ? '' : cookie.load('lj-pc-userInfo').memberId
    }).then(res => {
      this.setState({
        money: res.data.money
      })
    })
  }
  getImg = (plantNo) => {
    const node = document.getElementById("tiHuoPngImg");
    domtoimage.toPng(node).then((defaultUrl) => {
      const img = new Image();
      img.src = defaultUrl;
      img.setAttribute('className', 'pngImg');
      domtoimage.toBlob(node).then((blob) => {
        saveAs(blob, `${'提货单：' + plantNo}.png`)
      })
    }).catch(() => {
      console.log("error")
    })
  }
  futurePickup = () => {
    const { changeId } = this.state
    request('post', '/api/shop/future/pickup', {
      changeId
    }).then(res => {
      console.log(res);
      this.setState({
        futurePickup: res.data
      })
    })
  }

  saleOk = () => {
    this.setState({
      isShowBalance: false
    })
  };

  saleCancel = () => {
    this.setState({
      isShowBalance: false
    })
  };
  xiangqing(productId) {
    let productAllMoney = this.state.productAllMoney
    this.props.history.push({ pathname: '/home/futurestradingDetails', state: { productId, productAllMoney } })
  }
  //余额不足
  showBalance = () => {
    this.setState({
      isBalance: true
    })
  };

  BalanceOk = () => {
    this.setState({
      isBalance: false
    })
  };

  BalanceCancel = () => {
    this.setState({
      isBalance: false
    })
  };
  //提货单
  showTiHuo = (changeId) => {

    this.setState({
      isTiHuo: true,
      changeId
    }, function (params) {
      this.futurePickup()
    })
  };

  TiHuoOk = () => {
    this.setState({
      isTiHuo: false
    })
  };

  TiHuoCancel = () => {
    this.setState({
      isTiHuo: false
    })
  };
  //操作成功
  showIsSuccessfully = () => {

    this.setState({
      isSuccessfully: true
    }, function () {
      this.buyingCancel()
    })
  };

  successfullyOk = () => {
    this.setState({
      isSuccessfully: false
    })
  };

  successfullyCancel = () => {
    this.setState({
      isSuccessfully: false
    })
  };
  //聊一聊
  showIsChat = () => {
    window.open('http://p.qiao.baidu.com/cps/chat?siteId=16962199&userId=28008448&siteToken=b922f23b871b1f04ee1691553de11148')
    // this.setState({
    //   isChat: true
    // })
  };

  chatOk = () => {
    this.setState({
      isChat: false
    })
  };

  chatCancel = () => {
    this.setState({
      isChat: false
    })
  };

  //点击卖出显示弹窗
  showBalanceTab = (type) => {
    const { currentIndex } = this.state
    if (!cookie.load('lj-pc-userInfo')) {
      message.warning('请先登录')
    } else {
      request('post', '/api/member/user/info').then(res => {
        if (res.data.auth == 0) {
          message.warning('请先认证店铺信息')
        } else {
          this.setState({
            isBalanceTab: true,
            currentIndex: type
          }, function () {
            this.futureMyList()
          })
        }
      })
    }
  }

  balanceTabOk = () => {
    this.setState({
      isBalanceTab: false
    })
  }
  balanceTabCancel = () => {
    this.setState({
      isBalanceTab: false
    })
  }
  //下单调用详情api
  goodsInfo() {
    const { itemData } = this.state
    request('post', '/api/shop/future/goodsInfo', {
      productId: itemData.productId
    }).then(res => {
      console.log(res);
      if (res.code == 0) {
        this.setState({
          goodsInfo: res.data
        })
      }
    })
  }
  //点击买入显示弹窗
  showBuying = (item) => {
    if (!cookie.load('lj-pc-userInfo')) {
      message.warning('请先登录')
    } else {
      request('post', '/api/member/user/info').then(res => {
        if (res.data.auth == 0) {
          message.warning('请先认证店铺信息')
        } else {
          if (!item.productPrice) {
            message.warning('暂未设置价格，请先联系平台聊一聊')
          } else {
            this.setState({
              isBuying: true,
              itemData: item
            }, function (params) {
              this.goodsInfo()
            })
          }
        }
      })


    }
  }
  buyingOk = () => {
    this.setState({
      isBuying: false
    })
  }
  buyingCancel = () => {
    this.setState({
      isBuying: false
    })
  }
  details(item) {
    this.props.history.push({ pathname: '/home/futurestradingDetails/' + item.productId, state: { item } })
  }
  render() {
    const { breedList, transactionList, currentIndex, getGoodsList, futureMyList, itemData, futurePickup, goodsInfo, upNumSort, downSort, shiChangSort, zhangSort, buySort } = this.state
    return (
      <>
        <Carousel1 />
        <div className="futurestrading">
          <div className="futurestradingBlankTop">
          </div>
          <div className="futurestradingConten">
            <div className="futurestradingContenTopW">
              <div className="futurestradingContenTop">
                <div className="contentTopLeft">
                  <div className="breed">
                    <span className="span">品种</span>
                    <Select style={{ width: '120px', marginRight: '50px' }} onChange={(e) => this.breedMenu(e)} allowClear>
                      {
                        breedList.map(item => {
                          return <Option key={item.classId} value={item.className, item.classId}>{item.className}</Option>
                        })
                      }
                    </Select>
                  </div>
                  <div className="class">
                    <span className="span">等级</span>
                    <Select style={{ width: '120px', marginRight: '30px' }} onChange={(e) => this.classMenu(e)} allowClear>
                      <Option value="精品">精品</Option>
                      <Option value="优质">优质</Option>
                      <Option value="通货">通货</Option>
                      <Option value="花皮">花皮</Option>
                    </Select>
                  </div>
                  <div className="search"></div>
                </div>
                <div className="contentTopRight">
                  <span className="balance">
                    可用余额：{this.state.money}元
                  </span>
                  <div className="balanceBtn" onClick={() => this.showBalanceTab(0)}>
                    卖出
                  </div>
                  <div className="balanceBtn" onClick={() => this.showBalanceTab(2)}>
                    成交记录
                  </div>
                </div>
              </div>
            </div>
            <div className="futurestradingContenTable">
              <table className="listTable">
                <thead className="listThead">
                  <tr className="listTr">
                    <th scope="col" width="15%">合约号</th>
                    <th>种类名称</th>
                    <th>等级</th>
                    <th><div className="sort" onClick={() => this.numSort()}><span>数量(斤)</span> <span className="rightSort"> <CaretUpOutlined style={{ display: upNumSort ? 'block' : 'none', color: this.state.numColor }} /> <CaretDownOutlined style={{ display: downSort ? 'block' : 'none', color: this.state.numColor }} /></span></div></th>
                    <th><div className="sort" onClick={() => this.priceSort()}><span>单价</span> <span className="rightSort"> <CaretUpOutlined style={{ display: this.state.uppriceSort ? 'block' : 'none', color: this.state.priceColor }} /> <CaretDownOutlined style={{ display: this.state.downpriceSort ? 'block' : 'none', color: this.state.priceColor }} /></span></div></th>
                    <th><div className="sort" onClick={() => this.shiChangSort()}><span>市场价(斤)</span> <span className="rightSort"> <CaretUpOutlined style={{ display: this.state.upshiChangSort ? 'block' : 'none', color: this.state.shiChangColor }} /> <CaretDownOutlined style={{ display: this.state.downshiChangSort ? 'block' : 'none', color: this.state.shiChangColor }} /></span></div></th>
                    <th><div className="sort" onClick={() => this.zhangSort()}><span>涨幅</span> <span className="rightSort"> <CaretUpOutlined style={{ display: this.state.upzhangSort ? 'block' : 'none', color: this.state.zhangColor }} /> <CaretDownOutlined style={{ display: this.state.downzhangSort ? 'block' : 'none', color: this.state.zhangColor }} /></span></div></th>
                    <th><div className="sort" onClick={() => this.buySort()}><span>卖价</span> <span className="rightSort"> <CaretUpOutlined style={{ display: this.state.upNumbuySort ? 'block' : 'none', color: this.state.buyColor }} /> <CaretDownOutlined style={{ display: this.state.downbuySort ? 'block' : 'none', color: this.state.buyColor }} /></span></div></th>
                    <th>最高价格</th>
                    {/* <th>最低价格</th> */}
                    <th className="tabOperationTh">操作</th>
                  </tr>
                </thead>
                <tbody className="listTbody">
                  {
                    getGoodsList.list && getGoodsList.list.map(item => {
                      return <tr key={item.productId} className="TbodyTr" >
                        <td>{item.sku}</td>
                        <td>{item.className}</td>
                        <td>{item.goodsProp}</td>
                        <td>{item.productStock}</td>
                        <td>{item.productPrice}</td>
                        <td>{item.contryAvgPrice}</td>
                        <td>{item.contryAvgPriceGrowth}%</td>
                        <td>{item.productAllMoney}</td>
                        <td>{item.contryMaxPrice}</td>
                        {/* <td></td> */}
                        <td className="tabOperationTd">
                          <span onClick={() => this.showBuying(item)}>下单</span>
                          {/* <Link target="_blank" onClick={()=>this.details(item)} to={'/home/futurestradingDetails/' + item.productId }> */}
                          <span onClick={() => this.details(item)} >查看详情</span>
                          {/* </Link> */}
                          <span onClick={() => this.showIsChat()}>聊一聊</span>
                          <span onClick={() => this.zhiNeng()}>监控</span>
                        </td>
                      </tr>
                    })
                  }
                </tbody>
              </table>
            </div>
            <Pagination style={{ marginBottom: '57px' }} pageSize='10' current={getGoodsList.pageNum} onChange={this.pagination} defaultCurrent={1} total={getGoodsList.total} />
          </div>
        </div>



        {/* 点击卖出 */}
        <Modal title={`可用余额：${this.state.money}元`} footer={[]} className="balanceTabModal" visible={this.state.isBalanceTab} onOk={this.balanceTabOk} onCancel={this.balanceTabCancel}>
          <div className="balanceTab">
            <div className="transactionList">
              {
                transactionList && transactionList.map(item => {
                  return <div key={item.id} className="transactionItem" id={`${item.id == this.state.currentIndex ? 'active' : ''}`} onClick={() => this.setCurrentIndex(item.id)}>
                    {item.name}
                  </div>
                })
              }
            </div>
            <div className="positionTable">
              {
                currentIndex == 0 ?
                  <table className="listTable">
                    <thead className="listThead">
                      <tr className="listTr">
                        {/* <th>时间</th> */}
                        <th>类型</th>
                        <th>品种</th>
                        <th>等级</th>
                        <th>持仓量(斤)</th>
                        {/* <th>仓位</th> */}
                        <th>市场价</th>
                        <th>开仓盈亏</th>
                        <th className="tabOperationTh">操作</th>
                      </tr>
                    </thead>
                    <tbody className="listTbody">
                      {
                        futureMyList.list && futureMyList.list.map(item => {
                          return <tr key={item.productId} className="TbodyTr" >
                            {/* <td>12：23：32+</td> */}
                            <td>{item.productPost == '' || null ? '自存' : '交易'}</td>
                            <td>{item.className}</td>
                            <td>{item.goodsProp}</td>
                            <td>{item.productStock}</td>
                            {/* <td>一号仓24312+</td> */}
                            <td>{item.contryAvgPrice}</td>
                            <td>{item.subPrice}</td>
                            <td className="tabOperationTd">
                              <span onClick={() => this.showBalanceModal(item.productId, item.productStock)}>卖出</span>
                            </td>
                          </tr>
                        })
                      }
                    </tbody>
                  </table>
                  :
                  (currentIndex == 1 ?
                    <table className="listTable">
                      <thead className="listThead">
                        <tr className="listTr">
                          {/* <th>时间</th> */}
                          <th>品种</th>
                          <th>等级</th>
                          <th>委托价</th>
                          <th>数量（斤）</th>
                          <th>单价</th>
                          <th className="tabOperationTh">操作</th>
                        </tr>
                      </thead>
                      <tbody className="listTbody">

                        {
                          futureMyList.list && futureMyList.list.map(item => {
                            //console.log(item.productPrice + "委托价格");
                            return <tr key={item.productId} className="TbodyTr" >
                              {/* <td>12：23：32+++</td> */}
                              <td>{item.className}</td>
                              <td>{item.goodsProp}</td>
                              <td>{item.productAllMoney}</td>
                              <td>{item.productStock}</td>
                              <td>{item.productPrice}</td>
                              <td className="tabOperationTd">
                                <span onClick={() => this.futureGoodsCancel(item.productId)}>撤单</span>
                              </td>
                            </tr>
                          })
                        }
                      </tbody>
                    </table>
                    : (currentIndex == 2 ?
                      <table className="listTable">
                        <thead className="listThead">
                          <tr className="listTr">
                            <th>时间</th>
                            <th>合约号</th>
                            <th>品种</th>
                            <th>等级</th>
                            <th>单价</th>
                            <th>数量(斤）</th>
                            <th>手续费</th>
                            <th>实收（元）</th>
                          </tr>
                        </thead>
                        <tbody className="listTbody">
                          {
                            futureMyList.list && futureMyList.list.map(item => {
                              return <tr className="TbodyTr" key={item.productId}>
                                <td>{item.createAt}</td>
                                <td>{item.sku}</td>
                                <td>{item.className}</td>
                                <td>{item.goodsProp}</td>
                                <td>{item.productPrice}</td>
                                <td>{item.productStock}</td>
                                <td>{item.moneyService}</td>
                                <td>{item.allMoney}</td>
                              </tr>
                            })
                          }
                        </tbody>
                      </table> :
                      <table className="listTable">
                        <thead className="listThead">
                          <tr className="listTr">
                            <th>时间</th>
                            <th>合约号</th>
                            <th>品种</th>
                            <th>等级</th>
                            <th>单价</th>
                            <th>数量(斤）</th>
                            <th>仓库租金</th>
                            <th>实付（元）</th>
                            <th className="tabOperationTh">操作</th>
                          </tr>
                        </thead>
                        <tbody className="listTbody">
                          {
                            futureMyList.list && futureMyList.list.map(item => {
                              return <tr className="TbodyTr" key={item.productId}>
                                <td>{item.createAt}</td>
                                <td>{item.sku}</td>
                                <td>{item.className}</td>
                                <td>{item.goodsProp}</td>
                                <td>{item.productPrice}</td>
                                <td>{item.productStock}</td>
                                <td>{item.moneyStore}</td>
                                <td>{item.allMoney}</td>
                                <td className="tabOperationTd">
                                  <span onClick={() => this.showTiHuo(item.changeId)}>提货单</span>
                                </td>
                              </tr>
                            })
                          }
                        </tbody>
                      </table>
                    )
                  )
              }
              <Pagination style={{ marginBottom: '57px', marginTop: '20px', textAlign: 'center' }} pageSize='6' current={futureMyList.pageNum} onChange={this.positionPagination} defaultCurrent={1} total={futureMyList.total} />

            </div>
          </div>
        </Modal>

        {/* 持仓卖出 */}
        <Modal title={`可用余额：${this.state.money}元`} footer={[]} className="balanceModal" visible={this.state.isShowBalance} onOk={this.saleOk} onCancel={this.saleCancel}>
          <div className="balanceModalContent">
            <div className="balanceModalContentTop">
              <div className="weight">
                斤：<span>{this.state.productStock}</span>
              </div>
              <div className="unitPrice">
                单价（元）：<Input style={{ width: '120px', height: '40px' }} onChange={(e) => this.unitPriceVla(e)} placeholder="请输入" />
              </div>
            </div>
            <div className="balanceModalContentBottom" onClick={() => this.futureGoodsSell()}>
              <div>卖出</div>
              <div>{(this.state.productStock * this.state.unitPriceVla).toFixed(2)}</div>
            </div>
          </div>
        </Modal>

        {/* 持仓买入 */}
        <Modal title={`可用余额：${this.state.money}元`} footer={[]} className="addOrderModal" visible={this.state.isBuying} onOk={this.buyingCancel} onCancel={this.buyingCancel}>
          <div className="addOrderModalContent">
            <table className="listTable">
              <thead className="listThead">
                <tr className="listTr">
                  <th>合约号</th>
                  <th>品种</th>
                  <th>等级</th>
                  <th>数量(斤）</th>
                  <th>单价</th>
                  <th>合计</th>
                </tr>
              </thead>
              <tbody className="listTbody">
                <tr className="TbodyTr" >
                  <td>{goodsInfo.sku}</td>
                  <td>{goodsInfo.className}</td>
                  <td>{goodsInfo.goodsProp}</td>
                  <td>{goodsInfo.productWeight * goodsInfo.productStock}</td>
                  <td>{goodsInfo.productPrice}</td>
                  <td>{goodsInfo.moneyGoods}</td>
                </tr>
              </tbody>
            </table>
            <div className="summation">
              <div className="summationLeft">
                <div className="goodsPrice"><span className="goodsPriceName">商品费用：</span> <span className="goodsPriceValue">{goodsInfo.moneyGoods}</span> </div>
                <div className="rent"><span className="rentName">租金：</span> <span className="rentValue">{goodsInfo.moneyStore}</span> </div>
                <div className="unit">（每吨  {goodsInfo.moneyStore}元/年）</div>
              </div>
              <div className="summationRight">
                <span>实付：</span><span className="Price">{goodsInfo.allMoney}</span>
              </div>
            </div>
            <div className="addOrderModalContentBottom" onClick={() => this.futureGoodsBuy()}>
              <span>确认下单</span>
            </div>
          </div>
        </Modal>
        {/* 买入余额不足 */}
        <Modal className="balanceNoModal" footer={[]} title="提示" visible={this.state.isBalance} onOk={this.saleOk} onCancel={this.saleCancel}>
          <p>账户余额不足，是否前往充值？</p>
          <div className="balanceNoModalBtn">
            <div className="borrowMoney">
              去借款
            </div>
            <div className="topUp">
              去充值
            </div>
          </div>
        </Modal>

        {/* 提货单 */}
        <Modal className="tiHuoModal" footer={[]} title="提货单" visible={this.state.isTiHuo} onOk={this.TiHuoOk} onCancel={this.TiHuoCancel}>
          <div className="tiHuoContent" id="tiHuoPngImg">
            <div className="tiHuoContent_item">
              <div className="tiHuoContent_itemName">
                仓库地址：
              </div>
              <div className="tiHuoContent_Value">
                {futurePickup.address}
              </div>
            </div>
            <div className="tiHuoContent_item">
              <div className="tiHuoContent_itemName">
                卖家手机号：
              </div>
              <div className="tiHuoContent_Value">
                {futurePickup.shopPhone}
              </div>
            </div>
            <div className="tiHuoContent_item">
              <div className="tiHuoContent_itemName">
                卖家姓名：
              </div>
              <div className="tiHuoContent_Value">
                {futurePickup.shopName}
              </div>
            </div>
            <div className="tiHuoContent_item">
              <div className="tiHuoContent_itemName">
                入库单号：
              </div>
              <div className="tiHuoContent_Value">
                {futurePickup.plantNo}
              </div>
            </div>
            <div className="tiHuoContent_item">
              <div className="tiHuoContent_itemName">
                品种：
              </div>
              <div className="tiHuoContent_Value">
                {futurePickup.goodsClass}
              </div>
            </div>
            <div className="tiHuoContent_item">
              <div className="tiHuoContent_itemName">
                等级：
              </div>
              <div className="tiHuoContent_Value">
                {futurePickup.goodsProp}
              </div>
            </div>
            <div className="tiHuoContent_item">
              <div className="tiHuoContent_itemName">
                单位净重（斤）：
              </div>
              <div className="tiHuoContent_Value">
                {futurePickup.productWeight}
              </div>
            </div>
            <div className="tiHuoContent_item">
              <div className="tiHuoContent_itemName">
                数量(斤)：
              </div>
              <div className="tiHuoContent_Value">
                {futurePickup.productStock}
              </div>
            </div>
            <div className="tiHuoContent_item">
              <div className="tiHuoContent_itemName">
                总量（斤）：
              </div>
              <div className="tiHuoContent_Value">
                {futurePickup.productWeight * futurePickup.productStock}
              </div>
            </div>
            <div className="tiHuoContent_item">
              <div className="tiHuoContent_itemName">
                颍山红公章：
              </div>
              <div className="tiHuoContent_Value">
                <div className="cachet">
                </div>
              </div>
            </div>
          </div>
          <div className="tiHuoModalBtn">
            <Button type="primary" onClick={() => this.getImg(futurePickup.plantNo)}>保存提货单</Button>
          </div>
        </Modal>

        {/* 聊一聊 */}
        <Modal className="chatModal" footer={[<span className="liao">聊一聊</span>, <Button type="primary">发送</Button>]} title="辣过瘾锦州辣椒旗舰店" visible={this.state.isChat} onOk={this.chatOk} onCancel={this.chatCancel}>
          <div className="chatModalInfo">
            <span>合约号：123455</span>
            <span>品种：子弹头</span>
            <span>等级：优质</span>
            <span>单价：2.00元</span>
            <span>总价：2345.00元</span>
          </div>
        </Modal>
      </>
    )
  }
}
export default withRouter(Futurestrading)