import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import you from '../../static/img/youb.png';
import youLv from '../../static/img/youlv.png';
import './index.scss';
import request from '../../static/js/http';
import lajiaoIcon from '../../static/img/lajiaoIcon.png'
import qiaomuicon from '../../static/img/qiaomuicon.png'
import guanmuicon from '../../static/img/guanmuicon.png'
import qiuleiicon from '../../static/img/qiuleiicon.png'
import zhongmiaoicon from '../../static/img/zhongmiaoicon.png'
import penjingicon from '../../static/img/penjingicon.png'
import caopingicon from '../../static/img/caopingicon.png'
import zhongziicon from '../../static/img/zhongziicon.png'
import zizhiicon from '../../static/img/zizhiicon.png'
class SecondLevel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setOpacity: '',
      bigList: [],
      sListData: [],
      isShow: false,
      classId: '',
      setIndex: ''
    }
  }
  componentDidMount() {
    this.bigList()
  }
  bigList() {
    request('post', '/api/goods/class/bList', {
      typeId: 0
    }).then(res => {
      this.setState({
        bigList: res.data
      })
    })
  }

  setHover(classId, index) {
    this.setState({
      setOpacity: index,
      setIndex: classId
    }, function () {
      this.sList(classId)
    })
  }
  isShow(isShow) {
    this.setState({
      isShow
    }, function () {
      if (isShow) {
        this.sList(1)
      }

    })
  }
  //小分类
  sList(classId) {
    request('post', '/api/goods/class/sList', {
      classId
    }).then(res => {
      this.setState({
        sListData: res.data
      })
    })
  }
  jumpSecondLevel(classId, className) {
    const { setIndex } = this.state
    if (this.props.location.pathname != `${'/home/boutique/' + classId}`) {
      this.props.history.push({ pathname: `${'/home/boutique/' + classId}`, state: { classId, setIndex, className } })
      window.location.reload()
    } else {
      //   //   // this.props.classId(classId)
      this.setState({
        classId
      }, function () {
        //     // this.props.all();
        // this.props.getClassId(this.state.classId)
      })
    }
  }
  render() {
    const { setOpacity, bigList, sListData, isShow } = this.state
    return (
      <div className='secondlevel'>
        <div className="secondListW">
          <div className="secondList">
            <div className="secondListLeft">
              {
                bigList && bigList.map((item, index) => (
                  <div key={item.classId} 
                      onMouseEnter={
                        () => this.setHover(item.classId, index)
                      } 
                      style={{ 
                        background: setOpacity === index ? '#F9F9F9' : '',
                        color: setOpacity === '' ? '' : setOpacity === index ? '#FFFFFF' : '#707070' 
                      }} 
                      className="secondListLeftItemW">
                    <div className="secondListLeftItem">
                      <div className="secondListLeftItemSpan">
                      <img className="leftIcon" src={lajiaoIcon} alt="" />
                        {item.className}
                      </div>
                      <img src={setOpacity === '' ? youLv : setOpacity === index ? you : youLv} alt="" />
                    </div>
                  </div>
                ))
              }
              {/* <img className="leftIcon qiaomuicon" src={lajiaoIcon} alt="" />
              <img className="leftIcon guanmuicon" src={lajiaoIcon} alt="" />
              <img className="leftIcon qiuleiicon" src={lajiaoIcon} alt="" />
              <img className="leftIcon zhongmiaoicon" src={lajiaoIcon} alt="" />
              <img className="leftIcon penjingicon" src={lajiaoIcon} alt="" />
              <img className="leftIcon caopingicon" src={lajiaoIcon} alt="" />
              <img className="leftIcon zhongziicon" src={lajiaoIcon} alt="" />
              <img className="leftIcon zizhiicon" src={lajiaoIcon} alt="" /> */}
            </div>
            <div className={`secondListRightW`} >
              <div className="secondListRightN"></div>
              <div className="secondListRight">
                {
                  sListData && sListData.map((item, index) => (
                    <div key={item.classId} onClick={() => this.jumpSecondLevel(item.classId, item.className)} className="secondListRightItem" title={item.className}>
                      {item.className}
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default withRouter(SecondLevel)