import React, { Component } from 'react';
import { LineChartOutlined, FieldTimeOutlined } from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';
import './index.scss';
import request from '../../static/js/http';
import { splitDecimals } from '../../utils/tool'

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: {},
      searchData: [],
      pageNum: 1,
    };
  }
  componentDidMount() {
    this.search()
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      pageNum: nextProps.index1,
      goodsProp: nextProps.goodsProp
    }, function () {
      this.search()
    })
  }
  search() {
    request('post', '/api/goods/goods/appIndex', {
      classId: 0,
      pageNum: 1,
      pageSize: 10,
      lat: 1,
      lon: 1,
      sortType: 0,
    }).then(res => {
      this.setState({
        searchData: res.data
      })
    })
  }

  xiangqing(id) {
    this.props.history.push({ pathname: '/home/details', state: { id } })
  }
  render() {
    const { searchData } = this.state;
    return (
      <>
         <div className="itemitem">
          {searchData.list && searchData.list.map(item => (
              <Link  target="_blank" key={item.goodsId} to={'/home/details/' + item.goodsId}>
                <div className="goods-card">
                  <div className="itemPic">
                    {/* <LazyLoad height={200} offset={100}> */}
                      <img className="img" src={item.goodsImg} alt="" width={196} height={168} />
                      <div className="youxuan"></div>
                    {/* </LazyLoad> */}
                  </div>
                  <div className="content">
                    <div className="contentGoodsName ellipsis" title={item.goodsName}>
                      {item.goodsName}
                    </div>
                    <div className="contentPrice">
                      <span>¥</span>
                      <span className="ellipsis price-num">
                        <span className="price-integer">{splitDecimals(item.productPrice)[0]}</span>
                        <span className="price-decimals">{splitDecimals(item.productPrice)[1]}</span>
                      </span>
                      <span>/</span>
                      <span>{item.goodsUnit || '斤'}</span>
                    </div>
                    <div className="content-sale">
                      <div className="sale-num ellipsis">销量：{item.goodsSaleNum}</div>
                      <div className="comment-num">
                        {item.goodsCommentNum > 100 ? '100+评价' : item.goodsCommentNum + '评价'}
                      </div>
                    </div>
                    <div className="shop-name-wrapper">
                      <object>
                        <Link className="shop-name ellipsis" title={item.shopName} to={'/store/' + item.shopId}>
                          {item.shopName}
                        </Link>
                      </object>
                      {/* <img src={KefuIcon} alt="聊一聊" width={14} height={13} className='kefu-icon' onClick={(e) => this.openChatWindow(e, item.shopPhone)} /> */}
                    </div>
                  </div>
                </div>
              </Link>
            ))}
        </div>
      </>
    );
  }
}
export default withRouter(index)