import React, { Component } from 'react';
import { withRouter } from "react-router-dom"
import { Breadcrumb } from 'antd';
import './index.scss';
import moment from 'moment';
import request from '../../../static/js/http'
class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newsList: []
    };
  }
  componentDidMount() {
    this.newsList()
  }

  newsList() {
    request('post', '/api/cms/notice/list', {
      pageNum: 1,
      pageSize: 5
    }).then(res => {
      if (res.code === 0) {
        ////console.log(res);
        this.setState({
          newsList: res.data.list
        })
      }
    })
  }

  newsInfo(noticeId) {
    this.props.history.push({ pathname: '/home/newsitem', state: { noticeId } })
  }


  render() {
    return (
      <div className="news">
        <div className="breadcrumb">
          <div className="nei">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>当前位置</Breadcrumb.Item>
              <Breadcrumb.Item>消息列表</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="list">
          {
            this.state.newsList.map(item => (
              <div onClick={() => this.newsInfo(item.noticeId)} id={item.noticeId} key={item.noticeId} className="item hover">
                <div>{item.noticeTitle}</div>
                <div>
                {/* {moment(parseInt(item.createAt)).format("YYYY-MM-DD HH:mm:ss")}*/}
                {item.createAt}
                </div> 
              </div>
            ))
          }
        </div>
      </div>
    );
  }
}
export default withRouter(News)