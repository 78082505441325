import React, { Component } from 'react';
import './index.scss';
import { Button, message } from 'antd';
import { withRouter } from "react-router-dom"
import zfb from '../../../../static/img/zhifubao.png'
import vx from '../../../../static/img/vx.png'
import request from '../../../../static/js/http';
import QRCode from 'qrcode.react';
class Pay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payment: [
        { appUrl: zfb, name: '支付宝', id: 0 },
        { appUrl: vx, name: '微信', id: 1 }
      ],
      currentIndex: 0,
      rechargeAmountPrice: '',
      showElem: true,
      appName: '支付宝',
      codeUrl: '',
      iframe: '',
 
    }
  }
  componentDidMount() {

  }
  //充值接口
  recharge() {
    const { rechargeAmountPrice, currentIndex } = this.state
    request('post', '/api/member/money/rechargePc', {
      money: rechargeAmountPrice,
      type: currentIndex
    }).then(res => {
      if (currentIndex == 0) {
        const newWindow = window.open("", "_blank");
        newWindow.document.write(res);
        newWindow.focus();
      } else {
        this.setState({
          codeUrl: res.data.codeUrl
        })
      }
    })
  }

  //点击选中timeItem改变样式
  setCurrentIndex(e, appName) {
    // if (e == 1) {
    //   message.warning('微信支付暂未开通')
    // } else {
    this.setState({
      currentIndex: e,
      appName,
      showElem: true
    })
    // }

  }
  //充值金额
  rechargeAmountPrice(e) {
    if (e.target.value*1>=1 && e.target.value*1<=99999) {
      this.setState({
        rechargeAmountPrice: e.target.value+''
      })
    }else{
      message.warning('请输入1-99999的数字')
    }
  }
  atOnceTopUp() {
    const { rechargeAmountPrice, showElem } = this.state
    if (!rechargeAmountPrice) {
      message.warning('请填写充值金额')
    } else {
      this.setState({
        showElem: !showElem
      }, function (params) {
        this.recharge()
      })
    }
  }
  render() {
    const { payment, rechargeAmountPrice, showElem, codeUrl, iframe, currentIndex } = this.state

    return (
      <>
        <div className="rechargeAmount ">
          <div className="rechargeAmountInput">
            <input type="number"  min="1" max="99999" maxLength="5" placeholder="请输入充值金额" onBlur={(e) => this.rechargeAmountPrice(e)} /><span className="yuan">元</span>
            <div className="tiShi">
              请输入1-99999的数字
            </div>
          </div>
          <div className="modeOfPayment">
            <div className="modeOfPaymentTitle">
              请选择支付方式
            </div>
            <div className="paymentList">
              {
                payment.map(item => {
                  return <div key={item.id} className="paymentItem" id={`${item.id == this.state.currentIndex ? 'active' : ''}`} onClick={() => this.setCurrentIndex(item.id, item.name)} >
                    <img src={item.appUrl} alt="" />
                    <div className="paymentName">
                      {item.name}
                    </div>
                  </div>
                })
              }
            </div>
          </div>
          <div className="payBtn">
            {/* 立即充值 */}
            {
              showElem ? (<Button type="primary" onClick={() => this.atOnceTopUp()}>
                立即充值
              </Button>) :
                //二维码支付
                <div className="paymentCode">
                  <div className="xuzf">
                    需支付<span className="price">{rechargeAmountPrice}</span>元
                  </div>
                  {
                    currentIndex == 1 ? <div className="QRcode">
                      <QRCode
                        value={codeUrl && codeUrl}  //value参数为生成二维码的链接
                        size={180} //二维码的宽高尺寸
                        fgColor="#000000"  //二维码的颜色
                      />
                    </div> : ''
                  }

                  {/* <iframe srcdoc={iframe && iframe}
                    style={{width:600,height:600}}
                    qr_pay_mode='1'
                   >
                  </iframe> */}
                  <p className="paymentCodeP">请使用{this.state.appName}扫码支付</p>
                </div>
            }

            {/* 充值成功 */}
            {/* <div className="paySucceed">
              <p>充值成功</p>
              <Button type="primary">
                继续充值
              </Button>
            </div> */}

          </div>
        </div>
      </>
    )
  }
}
export default withRouter(Pay)