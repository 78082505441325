import React, { Component } from 'react';
import { Form, Input, Button, message } from 'antd';
import { CloseOutlined, MobileOutlined, MailOutlined, LockOutlined } from '@ant-design/icons';
import './index.scss';
import request from '../../../static/js/http';
import cookie from 'react-cookies'
import { withRouter } from "react-router-dom";
class Resetpasswords extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btnText: '发送验证码',
      btnBool: false,
      memberPhone: '',
      code: '',
      data: '',
      twonewPassword: ''
    };
  }

  resetpasswords() {
    const { memberPhone, code, newPassword, twonewPassword, data } = this.state
    // if (data == code) {
      if (newPassword == twonewPassword) {
        request('post', '/api/member/code/resetPassword', {
          memberPhone,
          code,
          newPassword,
        }).then(res => {
          if (res.code === 0) {
            // PubSub.publish('atguigu', { sta: true })
            console.log('PubSub');
          }
        })
      } else {
        message.info('两次密码不一致')
      }
    // } else {
    //   message.info('请输入正确的验证码')
    // }
  }



  onFinish = values => {
    // eslint-disable-next-line no-console
    ////console.log('Received values of form: ', values);
  };

  handerClick() {
    const { changeColor } = this.props;
    changeColor(true); // 执行父组件的changeColor 并传参 必须和父组件中的函数一模一样
  }
  // 获取input的值 手机号码
  input(v) {
    this.setState({
      memberPhone: v.target.value
    });
  }
  getcode(e) {
    this.setState({
      code: e.target.value
    });
  }
  mima = (v) => {
    this.setState({
      newPassword: v.target.value
    });
  }
  twomima = (v) => {
    this.setState({
      twonewPassword: v.target.value
    });
  }

  sendCode() {
    request("post", "/api/member/sms/send", {
      phone: this.state.memberPhone
    }).then(res => {
      ////console.log(res);
      this.setState({
        data: res.data
      })
    })
    const { memberPhone } = this.state;
    const text = /^1\d{10}$/;
    if (!text.test(memberPhone)) {
      message.error('手机号格式错误');
    } else {
      let maxTime = 60;
      this.timer = setInterval(() => {
        if (maxTime > 0) {
          --maxTime;
          this.setState({
            btnText: `重新获取${maxTime}s`,
            btnBool: true
          });
        } else {
          this.setState({
            btnText: '发送验证码',
            btnBool: false
          });
        }
      }, 1000);
      // axios.ajax({
      // }).then(res => {
      // });
    }
  }

  render() {
    // ////console.log('About组件收到的props是',this.props);
    const { btnText, btnBool } = this.state;
    return (
      <div id="forget-password">
        <div className="tablogin">
          <CloseOutlined className="del" onClick={() => { this.handerClick(); }} />
          <div>忘记密码</div>
        </div>
        <div className="switch">
          <Form
            name="normal_login1"
            className="login-form"
            autoComplete="off"
            initialValues={{
              remember: true
            }}
            size="large"
            onFinish={this.onFinish}
            id="mobilelogin"
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: '请输入手机号'
                }, {
                  pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
                  message: '不合法的手机号格式!'
                }
              ]}
            >
              <Input type="number" onChange={v => this.input(v)} maxLength="11" prefix={<MobileOutlined className="site-form-item-icon" />} placeholder="请输入手机号" />
            </Form.Item>
            <Form.Item className="verification">
              <Form.Item
                name="yan"
                className="yan"
                rules={[
                  {
                    required: true,
                    message: '请输入验证码!'
                  }, {
                    pattern: /^\d{6}$/,
                    message: '请输入正确的验证码!'
                  }

                ]}
              >
                <Input
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  placeholder="请输入验证码!"
                  type="number"
                  onChange={e => this.getcode(e)}
                />
              </Form.Item>
              <Button className="botton" onClick={() => this.sendCode()} type="primary" disabled={btnBool}>{btnText}</Button>
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: '请输入密码!'
                }
              ]}
            >
              <Input.Password
                onChange={v => this.mima(v)}
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="请输入密码"
              />
            </Form.Item>
            <Form.Item
              name="confirm"
              className="confirm"
              rules={[
                {
                  required: true,
                  message: '请再次输入密码!'
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('两次密码不一致'));
                  }
                })
              ]}
            >
              <Input.Password
                onChange={v => this.twomima(v)}
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="请输入密码"
              />
            </Form.Item>
            <Form.Item>
              <Button onClick={() => this.resetpasswords()} type="primary" htmlType="submit" className="login-form-button">
                登录
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}
export default withRouter(Resetpasswords);