import React, { useEffect, useState } from "react";
import "./index.scss";
import { Link } from "react-router-dom"
import { splitDecimals } from '../../../../utils/tool'
import request from '../../../../static/js/http';
import defaulImg from "../../../../static/img/defalutImg.png"
// import KefuIcon from '@/static/img/new-ui/kefu-icon.png'
import { message } from 'antd'
import cookie from 'react-cookies'
// import LazyLoad from 'react-lazyload'
function QinghuoAndXiangcun() {
  const [qinghuoList, setQinghuoList] = useState([]);
  const [xiangcunList, setXiangcunList] = useState([]);
  const [defaultImg, setDefaultImg] = useState([])
  const [defaultImgs, setDefaultImgs] = useState([defaulImg, defaulImg, defaulImg, defaulImg])


  const getList = () => {
    request('post', '/api/goods/goods/appIndex', {
      // memberId: !cookie.load("lj-pc-userInfo")
      //   ? ""
      //   : cookie.load("lj-pc-userInfo").memberId,
      // memberId: cookie.load("lj-pc-userInfo") ? cookie.load("lj-pc-userInfo").memberId : '',
      memberId: cookie.load("lj-pc-userInfo")?.memberId || '',
      classId: '',
      lat: 1,
      lon: 1,
      pageNum: 1,
      pageSize: 4,
      recomSy: 1
    }).then(res => {
      if (res.code == 0) {
        console.log('XiangcunList', res.data)
        setXiangcunList(res.data?.list || [])
        let defaultImg = [defaulImg, defaulImg, defaulImg, defaulImg]
        defaultImg.length = defaultImg.length - (res.data?.list.length || 0)
        setDefaultImg(defaultImg)
      }
    })

    request('post', '/api/goods/goods/appIndex', {
      // memberId: !cookie.load("lj-pc-userInfo")
      //   ? ""
      //   : cookie.load("lj-pc-userInfo").memberId,
      // memberId: cookie.load("lj-pc-userInfo") ? cookie.load("lj-pc-userInfo").memberId : '',
      memberId: cookie.load("lj-pc-userInfo")?.memberId || '',
      classId: '',
      lat: 1,
      lon: 1,
      pageNum: 1,
      pageSize: 4,
      recomSy: 2
    }).then(res => {
      if (res.code == 0) {
        // console.log('QinghuoList', res.data)
        setQinghuoList(res.data?.list || [])
        let defaultImg = [defaulImg, defaulImg, defaulImg, defaulImg]
        defaultImg.length = defaultImg.length-(res.data?.list.length || 0)
        setDefaultImgs(defaultImg)
      }
    })
  };
  useEffect(() => {
    getList();
  }, []);

  // 打开“聊一聊窗口”
  const openChatWindow = (event, id) => {
    event.preventDefault()
    if(!id) return
    if (!cookie.load('lj-pc-userInfo')) {
      message.warning('请登录')
    } else {
      const win = window.open('/home/Achat/' + id, 'newwindow', 'width=1200,height=820,left=200,top=123')
      win.focus()
    }
  }

  return (
    <div className="qinghuoAndXiangcun">
      <div className="xiangcunzhenxing">
        <Link className="shopName ellipsis"  to='/home/boutique/boutique?index=1'> <span className="XCimg"></span></Link>
        <div className="xiangcunzhenxingList">
          {xiangcunList.map(item => (
            <Link key={item.goodsId} className='goods-link' to={'/home/details/' + item.goodsId}>
                {/* <LazyLoad height={200} offset={100}> */}
                  <img src={item.goodsImg} alt="" className='goods-img' width={198} height={172} />
                {/* </LazyLoad> */}
                <div className="xiangcunzhenxingShopName ellipsis">{item.goodsName}</div>
                <div className="xiangcunzhenxingPrice">
                  <div className="contentPrice">
                    <span>¥&nbsp;</span>
                    <span className='ellipsis price-num'>
                      <span className='price-integer'>
                        {splitDecimals(item.productPrice)[0]}
                      </span>
                      <span className='price-decimals'>
                        {splitDecimals(item.productPrice)[1]}
                      </span>
                    </span>
                    <span>/</span>
                    <span>{item.goodsUnit||"斤"}</span>
                  </div>
                  {item.hasCoupon ? <span className='quan-icon'></span> : ""}
                </div>
                <div className='xiangcunzhenxingdianpuName'>
                  <div className='contentdianpu' title={item.shopName}>
                    <object>
                      <Link className="shopName ellipsis" title={item.shopName} to={'/store/' + item.shopId}>{item.shopName}</Link>
                    </object>
                    {/* <img src={KefuIcon} alt="聊一聊" width={14} height={13} className='kefu-icon' onClick={(e) => openChatWindow(e, item.shopPhone)} /> */}
                  </div>
                  {/* <div className='cart-icon'></div> */}
                </div>
            </Link>
          )
          )}
          {
            defaultImg.map((item, idx) => (
              <span key={idx}>
                {/* <img src={item} alt="" /> */}
              </span>
            ))
          }
        </div>

      </div>
      <div className="qinghuozhuanqu">
        <div className="xiangcunzhenxingList xiangcunzhenxingLists">
          {qinghuoList.map(item => (
            <Link key={item.goodsId} className='goods-link' to={'/home/details/' + item.goodsId}>
                {/* <LazyLoad> */}
                  <img src={item.goodsImg} alt="" className='goods-img' width={198} height={172} />
                {/* </LazyLoad> */}
                <div className="xiangcunzhenxingShopName ellipsis">{item.goodsName}</div>
                <div className="xiangcunzhenxingPrice">
                  <div className="contentPrice">
                    <span>¥</span>
                    <span className='ellipsis price-num'>
                      <span className='price-integer'>
                        {splitDecimals(item.productPrice)[0]}
                      </span>
                      <span className='price-decimals'>
                        {splitDecimals(item.productPrice)[1]}
                      </span>
                    </span>
                    <span>/</span>
                    <span>{item.goodsUnit||"斤"}</span>
                  </div>
                  {item.hasCoupon ? <span className='quan-icon'></span> : ""}
                </div>
                <div className='xiangcunzhenxingdianpuName'>
                  <div className='contentdianpu' title={item.shopName}>
                    <object>
                      <Link className="shopName ellipsis" title={item.shopName} to={'/store/' + item.shopId}>{item.shopName}</Link>
                    </object>
                    <img src={KefuIcon} alt="聊一聊" width={14} height={13} className='kefu-icon' onClick={(e) => openChatWindow(e, item.shopPhone)} />
                  </div>
                  <div className='cart-icon'></div>
                </div>
            </Link>
          )
          )}
          {
            defaultImgs.map((item, idx) => (
              <span key={idx}>
                {/* <img src={item} alt="" /> */}
              </span>
            ))
          }
        </div>
        <Link className="shopName ellipsis"  to='/home/boutique/boutique?index=2'><span className="QHimg"></span></Link>
      </div>
    </div>
  );
}
export default QinghuoAndXiangcun
