import React, { Component } from 'react';
import moment from 'moment'
import {
  Breadcrumb, Image, message, InputNumber, Carousel, Avatar, Rate, Pagination, Modal, Button, Space, Popconfirm
} from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { HeartOutlined, ShareAltOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import copy from 'copy-to-clipboard';
import List1 from './List';
import './index.scss';
import lun from '../../../static/img/del_home_lun.png';
import geren from '../../../static/img/geren.png';
import qiye from '../../../static/img/qiye.png';
import del1 from '../../../static/img/del_img1.png';
import del2 from '../../../static/img/del_img2.png';
import ipo from '../../../static/img/ipo.png';
import request from '../../../static/js/http'
import cookie from 'react-cookies'
const { confirm } = Modal;
class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s: true,
      list: [
        { id: 0, src: del1, sty: true },
        { id: 1, src: del2, sty: false },
        { id: 2, src: del2, sty: false },
        { id: 3, src: del2, sty: false }],
      list2: [{ id: 0, name: '花皮', sty: true }, { id: 1, name: '通货', sty: false }],
      index: 0, // 图片下
      sum: 1, // 商品个数
      index2: 0,
      index3: 0, // 0商品详情 1评论
      index4: 0, // 评论类别
      shopInfo: [],
      goodsProduct: [],
      pingjiaList: [],
      shoucang: '收藏',
      fenye: '',
      imgurl: '',
      isModalVisible: false,
      tuijian: [],
      address: [],
      receipt: [],
      hotList: [],
      goodsId: '',
      activeIndex: 0,
      productId: '',
      goodReputationList: [],
      mediumReviewList: [],
      negativCommentList: [],
      allCommentList: [],
      shopCollectValue: '收藏',
      pageNum: 1,
      zhiboUrl: ''
    };
  }

  img = React.createRef();
  componentDidMount() {
    this.xiangqing()
    this.pingjiaList()
    this.hotList()
    this.goodReputation()
    this.mediumReview()
    this.negativComment()
    this.allComment()
    request('post', '/api/goods/video/info', {
      goodsId: this.props.match.params.id
    }).then(res => {
      console.log(res);
      this.setState({
        zhiboUrl: res.data.url
      })
    })
  }
  componentWillUnmount = () => {
    this.setState = (state, callback) => {
      return;
    };

  }
  xiangqing() {
    let goodsId = this.props.match.params.id
    if (!cookie.load('lj-pc-userInfo')) {
      let { index2 } = this.state
      let memberId = ''
      request('post', '/api/goods/goods/info', {
        goodsId,
        memberId
      }).then(res => {
        ////console.log(res);
        if (res.code === 0) {
          this.setState({
            shopInfo: res.data,
            goodsProduct: res.data.goodsProduct,
            index2: res.data.isCollect
          }, function () {

            if (this.state.index2 === 0) {
              this.setState({
                shoucang: '收藏'
              })
            }
            this.sta()
            this.shopCollect()
            this.tuijian()
          })
        }
      })
    } else {
      let { index2 } = this.state
      let goodsId = this.props.match.params.id
      let memberId = cookie.load('lj-pc-userInfo').memberId
      request('post', '/api/goods/goods/info', {
        goodsId,
        memberId
      }).then(res => {
        ////console.log(res);
        if (res.code === 0) {
          this.setState({
            shopInfo: res.data,
            goodsProduct: res.data.goodsProduct,
            index2: res.data.isCollect
          }, function () {
            this.sta()
            this.shopCollect()
            this.tuijian()
            if (this.state.index2 === 0) {
              this.setState({
                shoucang: '收藏'
              })
            } else {
              this.setState({
                shoucang: '已收藏'
              })
            }
          })
        }
      })
    }
  }

  //判断是否recomXq是包邮还是商议

  //查看店铺是否收藏
  shopCollect() {
    const { shopInfo } = this.state
    let shopId = shopInfo.shopId
    request('post', '/api/shop/shop/info', {
      memberId: cookie.load('lj-pc-userInfo') ? cookie.load('lj-pc-userInfo').memberId : '',
      shopId
    }).then(res => {
      ////console.log(res);
      if (res.data.shopCollect == 0) {
        this.setState({
          shopCollectValue: '收藏'
        })
      } else {
        this.setState({
          shopCollectValue: '已收藏'
        })
      }
    })
  }
  //店铺收藏
  shopshoucang() {
    if (!cookie.load('lj-pc-userInfo')) {
      message.warning('请先登录')
    } else {
      const { shopInfo } = this.state
      let shopId = shopInfo.shopId
      let memberId = cookie.load('lj-pc-userInfo').memberId
      request('post', '/api/shop/shop/collect', {
        memberId,
        shopId
      }).then(res => {
        ////console.log(res);
        if (this.state.shopCollectValue == '收藏') {
          message.success(res.msg)
        } else {
          message.success(res.msg)
        }
        this.shopCollect()
      })
    }

  }

  //店长推荐
  tuijian() {
    const { shopInfo } = this.state
    let shopId = shopInfo.shopId
    console.log(shopId);
    request('post', '/api/goods/goods/otherList', {
      memberId: cookie.load('lj-pc-userInfo') ? cookie.load('lj-pc-userInfo').memberId : '',
      type: 0,
      pageNum: 1,
      pageSize: 10,
      shopId: shopId
    }).then(res => {
      ////console.log(res);
      this.setState({
        tuijian: res.data
      })
    })
  }



  //全部评论
  pingjiaList(type) {
    const { index4 } = this.state
    console.log(index4);
    let goodsId = this.props.match.params.id
    request('post', '/api/order/order/commentList', {
      goodsId,
      memberId: !cookie.load('lj-pc-userInfo') ? '' : cookie.load('lj-pc-userInfo').memberId,
      pageNum: 1,
      pageSize: 10,
      type: index4
    }).then(res => {
      if (res.code === 0) {
        this.setState({
          pingjiaList: res.data.list,
          fenye: res.data
        })
      }
    })
  }
  //全部
  allComment() {
    let goodsId = this.props.match.params.id
    request('post', '/api/order/order/commentList', {
      goodsId,
      memberId: !cookie.load('lj-pc-userInfo') ? '' : cookie.load('lj-pc-userInfo').memberId,
      pageNum: 1,
      pageSize: 9999,
      type: 0
    }).then(res => {
      ////console.log(res);
      ////console.log(res.data.list);
      if (res.code === 0) {
        this.setState({
          allCommentList: res.data.list
        })
      }
    })
  }

  //好评
  goodReputation() {
    let goodsId = this.props.match.params.id
    request('post', '/api/order/order/commentList', {
      goodsId,
      memberId: !cookie.load('lj-pc-userInfo') ? '' : cookie.load('lj-pc-userInfo').memberId,
      pageNum: 1,
      pageSize: 9999,
      type: 3
    }).then(res => {
      ////console.log(res);
      ////console.log(res.data.list);
      if (res.code === 0) {
        this.setState({
          goodReputationList: res.data.list
        })
      }
    })
  }
  //中评
  mediumReview() {
    let goodsId = this.props.match.params.id
    request('post', '/api/order/order/commentList', {
      goodsId,
      memberId: !cookie.load('lj-pc-userInfo') ? '' : cookie.load('lj-pc-userInfo').memberId,
      pageNum: 1,
      pageSize: 9999,
      type: 2
    }).then(res => {
      ////console.log(res);
      ////console.log(res.data.list);
      if (res.code === 0) {
        this.setState({
          mediumReviewList: res.data.list
        })
      }
    })
  }
  //差评
  negativComment() {
    let goodsId = this.props.match.params.id
    request('post', '/api/order/order/commentList', {
      goodsId,
      memberId: !cookie.load('lj-pc-userInfo') ? '' : cookie.load('lj-pc-userInfo').memberId,
      pageNum: 1,
      pageSize: 10,
      type: 1
    }).then(res => {
      ////console.log(res);
      ////console.log(res.data.list);
      if (res.code === 0) {
        this.setState({
          negativCommentList: res.data.list
        })
      }
    })
  }
  pagination = (a, b) => {
    ////console.log(a, b);
    let goodsId = this.props.match.params.id
    let memberId = cookie.load('lj-pc-userInfo').memberId
    request('post', '/api/order/order/commentList', {
      goodsId,
      memberId,
      pageNum: a,
      pageSize: b
    }).then(res => {
      ////console.log(res);
      if (res.code === 0) {
        this.setState({
          pingjiaList: res.data.list,
          fenye: res.data
        })
      }
    })
  }
  addcart() {
    if (!cookie.load('lj-pc-userInfo')) {
      message.warning('请登录')
    } else {
      const { shopInfo } = this.state
      if (shopInfo.recomXq == 1) {
        message.warning('需商议商品不能加入购物车')
      } else {
        let memberId = cookie.load('lj-pc-userInfo').memberId
        let num = this.state.sum
        const { productId, shopInfo } = this.state
        request('post', '/api/member/cart/add', {
          productId: !productId ? shopInfo.productList[0].productId : productId,
          memberId,
          num
        }).then(res => {
          if (res.code === 0) {
            Modal.success({
              content: res.msg,
            });
          } else {
            Modal.warning({
              content: res.msg,
            });
          }
        })
      }

    }
  }
  addOrder(shopInfo, goodsProp) {
    if (!cookie.load('lj-pc-userInfo')) {
      message.warning('请登录')
    } else {
      const { productId, shopInfo } = this.state
      let memberId = cookie.load('lj-pc-userInfo').memberId
      let num = this.state.sum
      request('post', '/api/order/goods/check', {
        productId: !productId ? shopInfo.productList[0].productId : productId,
        memberId,
        num,
      }).then(res => {
        ////console.log(res);
        if (res.code === 0) {
          Modal.success({
            content: res.msg,
          });
          let data = res.data
          this.props.history.push({ pathname: '/home/confirmorder', state: { data, goodsProp } })
        } else {
          Modal.warning({
            content: res.msg,
          });
        }
      })
    }
  }
  //热销榜
  hotList() {
    const { pageNum } = this.state
    request('post', '/api/goods/goods/otherList', {
      memberId: cookie.load('lj-pc-userInfo') ? cookie.load('lj-pc-userInfo').memberId : '',
      type: 0,
      pageNum,
      pageSize: 5,
    }).then(res => {
      ////console.log(res);
      this.setState({
        hotList: res.data
      })
    })
  }




  details(goodsId) {
    if (!cookie.load('lj-pc-userInfo')) {
      let { index2 } = this.state
      let memberId = ''
      request('post', '/api/goods/goods/info', {
        goodsId,
        memberId
      }).then(res => {
        ////console.log(res);
        if (res.code === 0) {
          this.setState({
            shopInfo: res.data,
            goodsProduct: res.data.goodsProduct,
            index2: res.data.isCollect
          }, function () {
            if (this.state.index2 === 0) {
              this.setState({
                shoucang: '收藏'
              })
            } else {
              this.setState({
                shoucang: '已收藏'
              })
            }
            this.sta()
          })
        }
      })
    } else {
      let { index2 } = this.state
      let memberId = cookie.load('lj-pc-userInfo').memberId
      request('post', '/api/goods/goods/info', {
        goodsId,
        memberId
      }).then(res => {
        if (res.code === 0) {
          this.setState({
            shopInfo: res.data,
            goodsProduct: res.data.goodsProduct,
            index2: res.data.isCollect
          }, function () {
            this.sta()
            if (this.state.index2 === 0) {
              this.setState({
                shoucang: '收藏'
              })
            } else {
              this.setState({
                shoucang: '已收藏'
              })
            }
          })
        }
      })
    }
  }
  sta = picurl => {
    // 显示图片
    const { shopInfo, imgurl } = this.state;
    console.log(shopInfo);
    if (imgurl == '') {
      this.setState({
        imgurl: shopInfo.bannerList == '' ? '' : shopInfo.bannerList[0].imgurl
      })
    } else {
      this.setState({
        imgurl: picurl
      }, function () {
      })
    }
  }



  sta1 = () => {
    if (!cookie.load('lj-pc-userInfo')) {
      message.warning('请登录')
    } else {
      let memberId = cookie.load('lj-pc-userInfo').memberId
      let goodsId = this.props.match.params.id
      // const { index2 } = this.state;
      request('post', '/api/member/goods/collectOrCancel', {
        memberId,
        goodsId
      }).then(res => {
        ////console.log(res);
        if (res.data === 0) {

          message.success(res.msg)
          this.setState({
            index2: res.data,
            shoucang: '收藏'
          })
        } else {
          message.success(res.msg)
          this.setState({
            index2: res.data,
            shoucang: '已收藏'
          })
        }
      })
    }

  }

  sta2 = id => {
    // 显示图片
    const { list2 } = this.state;
    const b = list2.map(a => {
      a.sty = false;
      return a;
    });
    // ////console.log(id, b);
    b[id].sty = true;
    this.setState({ list2: b });
  }

  sta3 = id => {
    this.setState({
      index3: id
    });
  }

  sta4 = id => {
    this.setState({
      index4: id
    }, function (params) {
      this.pingjiaList()
    });
  }
  skuTab = (index, productId) => {
    this.setState({
      activeIndex: index,
      productId
    })
  }
  showsuyuan = () => {
    this.setState({
      isModalVisible: true
    })
  };

  suyuanOk = () => {
    this.setState({
      isModalVisible: false
    })
  };

  suyuanCancel = () => {
    this.setState({
      isModalVisible: false
    })
  };
  fuzi = () => {
    // 把内容复制到粘贴板
    copy(window.location.href);
    message.success('复制成功，去分享给好友吧');
  }
  showConfirm() {
    let that = this
    confirm({
      title: '请先添加地址',
      icon: <ExclamationCircleOutlined />,
      content: '是否跳转到地址管理添加地址',
      onOk() {
        let pathname = that.props.location.pathname
        that.props.history.push({ pathname: '/my/dizhi', state: { pathname } })
      },
      onCancel() {

      },
    });
  }
  zhiNeng() {
    const { zhiboUrl } = this.state
    window.open('http://zhibo.yingshanhongcn.com/?id=' + zhiboUrl)
  }
  onChange = value => {
    // eslint-disable-next-line no-console
    ////console.log('changed', value);
    this.setState({
      sum: value
    });
  }

  onChange1 = (a, b, c) => {
    // eslint-disable-next-line no-console
    ////console.log(a, b, c);
  }

  next = () => {
    const { pageNum } = this.state;
    this.setState({
      pageNum: pageNum - 1
    }, function () {
      this.hotList()
    });
  }

  prev = () => {
    const { pageNum } = this.state;
    this.setState({
      pageNum: pageNum + 1
    }, function () {
      this.hotList()
    });
  }

  render() {
    const {
      s, list, shoucang, tuijian, hotList, imgurl, index2, shopCollectValue, sum, index3, index4, shopInfo, goodsProduct, pingjiaList, fenye, pageNum
    } = this.state;
    var goodsParam = eval(shopInfo.goodsParam)
    //console.log(hotList);
    const text = shopInfo.shopPhone
    function confirm() {
      copy(text)
      message.success('复制成功，去📞吧');
    }
    return (
      <div className="details">
        <div className="towai">
          <div className="tonei">
            <img className="to1" src={shopInfo.shopLogo} alt=" " />
            <Link target="_blank" to={'/store/' + shopInfo.shopId}>
              <div className="to2">
                <div className="sheng">{shopInfo.shopName}</div>
                <div>信誉分:{shopInfo.shopStar}分</div>
                <div className="sheng">{shopInfo.shopAddress}</div>
                <div>身份认证：{s ? <img src={qiye} alt="企业" /> : <img src={geren} alt="认证类别" />}</div>
                <div className="sheng">主营类目：{shopInfo.shopMain}</div>
              </div>
            </Link>
            <div className="to3">
              <div>
                <div>累计成交额</div>
                <div>{shopInfo.shopMoney}</div>
              </div>
              <div>
                <div>订单</div>
                <div>{shopInfo.orderNum}单</div>
              </div>
              <div>
                <div>信誉值</div>
                <div>{shopInfo.shopStar}分</div>
              </div>
              <div>
                <div>访客</div>
                <div>{shopInfo.memberNum}</div>
              </div>
            </div>
            <div className="to4">
              {/* <div>聊一聊</div> */}
              <div onClick={() => this.shopshoucang()}>{shopCollectValue}</div>
            </div>
          </div>
        </div>
        <div className="width1400">
          <Breadcrumb separator=">">
            <Breadcrumb.Item>当前位置</Breadcrumb.Item>
            <Breadcrumb.Item>商品详情</Breadcrumb.Item>
          </Breadcrumb>
          <div className="xiangqing">
            <div className="left">
              <div className="lefttop">
                <div className="leftleft">
                  <Image
                    width={350}
                    height={350}
                    src={!imgurl ? shopInfo.goodsImg : imgurl}
                    placeholder={true}
                  />
                  <div className="list">
                    {
                      shopInfo.bannerList && shopInfo.bannerList.map(a => (
                        <div key={a.imageId} onClick={() => this.sta(a.imgurl)}>
                          <img
                            key={a.imageId}
                            className={`imglist hover ${a.delFlag ? 'active' : ''}`}
                            src={!a.imgurl ? '' : a.imgurl}
                            alt="aaaaa"
                          />
                        </div>
                      ))
                    }
                    <img src="" alt="" />
                  </div>
                  <div className="an">
                    <span onClick={this.sta1}>
                      <HeartOutlined style={{ color: index2 === 0 ? '#666666' : '#BD2C21' }} /><span>{shoucang}</span>
                    </span>
                    <span onClick={this.fuzi}><ShareAltOutlined /><span>分享</span></span>
                  </div>
                </div>
                <div className="leftright">
                  <div className="on1">
                    <div className="margin38 test">{shopInfo && shopInfo.recomSy == 0 ? '优品优选' : '乡村振兴'}</div>
                    <div className="goodsName">{shopInfo.goodsName}</div>
                  </div>
                  <div className="on2">
                    <div>
                      <div>价格:</div>
                      <div>起批量:</div>
                    </div>
                    {
                      <div className="on2nei">
                        {shopInfo.productList && shopInfo.productList.map((a, index) => (
                          <div key={index}>
                            <span>{a.productPrice}{shopInfo && shopInfo.recomSy == 0 ? '元/斤' : '元'}</span>
                          </div>
                        ))}
                        {shopInfo.productList && shopInfo.productList.map((a, index) => (
                          <div key={index}>
                            <span>{a.productWeight}斤起批</span>
                          </div>
                        ))}
                      </div>
                    }
                  </div>
                  <div className="on3">
                    <div className="margin38">发货地址:</div>
                    <div>
                      {shopInfo.shopProvince}
                      {shopInfo.shopCity}
                      {shopInfo.shopArea}
                      {shopInfo.shopAddress}
                    </div>
                  </div>
                  <div className="on4">
                    <div className="margin38">商品规格:</div>
                    <div>
                      {shopInfo.productList && shopInfo.productList.map((a, index) => (
                        <div onClick={() => this.skuTab(index, a.productId)} key={a.productId} className={`button hover ${index == this.state.activeIndex ? 'active' : ''}`}>
                          <span>{a.productWeight}斤</span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="on4">
                    <div className="margin38">商品等级:</div>
                    <div>{shopInfo.goodsProp}
                    </div>
                  </div>
                  <div className="on5">
                    <div className="margin38">数量:</div>
                    <div>
                      {/* max={100} */}
                      <InputNumber
                        defaultValue={1}
                        min={1}
                        formatter={value => `${value}包`}
                        parser={value => value.replace('包', '')}
                        onChange={this.onChange}
                      />
                      <div>（总重：{sum * goodsProduct.productWeight}斤)</div>
                    </div>
                  </div>
                  <div className="on6">
                    <div className="hover" onClick={() => this.addcart()}>加入购物车</div>
                    <div className="hover" onClick={() => this.addOrder(shopInfo, shopInfo.goodsProp)}>立即采购</div>
                    {
                      // console.log(shopInfo.productList && shopInfo.productList[0].batchId)
                      shopInfo.productList && !shopInfo.productList[0].batchId ? <></> : <>
                        <div className="hover" onClick={() => this.showsuyuan()}>商品溯源</div>
                        <div className="hover" onClick={() => this.zhiNeng()}>智能化仓储</div>
                      </>
                    }

                  </div>
                  <Modal title="商品溯源"
                    visible={this.state.isModalVisible}
                    onOk={this.suyuanOk}
                    onCancel={this.suyuanCancel}
                    cancelText={'取消'}
                    okText={'确定'}
                  >
                    <div>
                      品种：{shopInfo.shopMain}
                    </div>
                    <div>
                      等级：{shopInfo.goodsProp}
                    </div>
                    <div>货源地：
                      {shopInfo.shopProvince}
                      {shopInfo.shopCity}
                      {shopInfo.shopArea}</div>



                    {/* {
                      goodsParam && goodsParam.map((item, index) => {
                        return (
                          <>
                          <span key={index}>{item.paramsName}：</span>
                          <span key={index}>{item.paramsValue}</span>
                          </>
                        )
                        
                      })
                    } */}
                  </Modal>
                  <Popconfirm
                    placement="topRight"
                    title={text}
                    onConfirm={confirm}
                    okText="复制"
                    cancelText="取消"
                  >
                    <img className="on7 hover" src={ipo} alt="打电话" />
                  </Popconfirm>
                </div>
              </div>
              <div className="leftbottom">
                <div className="title">店长推荐</div>
                <div className="list">
                  {
                    tuijian.list && tuijian.list.map(item => {
                      return <Link target="_blank" key={item.goodsId} to={'/home/details/' + item.goodsId}>
                        <div className="item ">
                          <img className="hover" src={item.goodsImg} alt="" />
                          <div>
                            <div>{item.productPrice}元/斤</div>
                            <div>成交量{item.goodsSaleNum ? item.goodsSaleNum : '0'}</div>
                          </div>
                        </div>
                      </Link>
                    })
                  }
                </div>
              </div>
            </div>
            <div className="right">
              <div className="title">热销榜</div>
              <Carousel afterChange={this.onChange1} ref={this.img}>
                <div>
                  {
                    hotList.list && hotList.list.map(a => (
                      <Link target="_blank" key={a.goodsId} to={'/home/details/' + a.goodsId}>
                        <div className="list hover">
                          <div>
                            <img src={a.goodsImg} alt="辣椒" />
                            <div className="jia">￥{a.productPrice}/元/斤</div>
                          </div>
                          <div>{a.goodsName}</div>
                        </div>
                      </Link>
                    ))
                  }
                </div>
              </Carousel>
              <div className="zuoyou">
                <Button icon={<DownOutlined />} disabled={this.state.pageNum <= 1} onClick={() => this.next()} />
                <Button icon={<UpOutlined />} disabled={this.state.pageNum >= hotList.pages} onClick={() => this.prev()} />
              </div>
            </div>
          </div>
        </div>
        <div className="width1400 bo">
          <div className="left1">
            <div className="title">商家还在供应</div>
            <div className="list">
              {
                tuijian.list && tuijian.list.map(a => (
                  <Link target="_blank" key={a.goodsId} to={'/home/details/' + a.goodsId}>
                    <div className="item">
                      <img className="hover" src={a.goodsImg} alt={a} />
                      <div>{a.goodsName}</div>
                      <div>￥{a.productPrice}/斤</div>
                    </div>
                  </Link>

                ))
              }
            </div>
          </div>
          <div className="right1">
            <div className="top">
              <div onClick={() => this.sta3(0)} className={index3 === 0 ? 'sta' : ''}>商品介绍</div>
              <div onClick={() => this.sta3(1)} className={index3 === 1 ? 'sta' : ''}>评价({this.state.allCommentList.length})</div>
            </div>
            {index3 === 0 ? (
              <div className="xiang">
                {shopInfo.detailImgList && shopInfo.detailImgList.map(a => (
                  <img className="img" key={a.imageId} src={a.imgurl} alt={a} />
                ))}
              </div>
            )
              : (
                <div className="pinglun">
                  <div className="sty">
                    <div onClick={() => this.sta4(0)} className={`hover ${index4 === 0 ? 'sta' : ''}`}>全部（{this.state.allCommentList.length}）</div>
                    <div onClick={() => this.sta4(3)} className={`hover ${index4 === 3 ? 'sta' : ''}`}>好评（{this.state.goodReputationList.length}）</div>
                    <div onClick={() => this.sta4(2)} className={`hover ${index4 === 2 ? 'sta' : ''}`}>中评（{this.state.mediumReviewList.length}）</div>
                    <div onClick={() => this.sta4(1)} className={`hover ${index4 === 1 ? 'sta' : ''}`}>差评（{this.state.negativCommentList.length}）</div>
                  </div>
                  <div className="list">
                    {
                      pingjiaList.map(a => (
                        <div className="item" key={a.commentId}>
                          <Avatar
                            className="img"
                            size={50}
                            src={a.memberAvator}
                          />
                          <div className="right2">
                            <div className="q1">{a.memberName}</div>
                            <div className="q2">
                              <Rate disabled allowHalf defaultValue={a.star} />
                              <span className="fen">{a.star}分</span>
                            </div>
                            <div className="q3" dangerouslySetInnerHTML={{ __html: `${a.content}` }}></div>
                            <div className="q4">
                              <Image.PreviewGroup>
                                {
                                  a.commentImg && a.commentImg.split(",").map((pic, index) => {
                                    return <Image
                                      key={index}
                                      width={60}
                                      height={60}
                                      src={pic}
                                    />
                                  })
                                }
                              </Image.PreviewGroup>
                            </div>
                            <div className="q5">{moment(a.createAt).format('YYYY-MM-DD')}</div>
                          </div>
                        </div>
                      ))
                    }
                    <Pagination defaultCurrent={1} current={fenye.pageNum} onChange={this.pagination} total={fenye.total} />
                  </div>

                </div>

              )}

          </div>
        </div>

      </div>
    );
  }
}
export default withRouter(Details)