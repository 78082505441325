import React, { Component } from 'react';
import request from '../../static/js/http';
import './zixun.scss';
import gonggaoicon from '../../static/img/gonggaoicon.png'
import { withRouter } from 'react-router-dom';
import { Carousel } from 'antd';
class Newsitem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: []
        }
    }
    componentDidMount() {
        this.getList()
    }
    getList = () => {
        request('get', '/api/cms/notice/list', {
            pageNum: 1,
            pageSize: 20,
        }).then(res => {
            if (res.code === 0) {
                this.setState({
                    list: res.data.list
                })
            }
        })
    }
    jumpNew(noticeId) {
        this.props.history.push({ pathname: '/home/newsitem', state: { noticeId } })
    }

    render() {
        const lunboSetting = {
            dots: true,
            lazyLoad: true,
            autoplay: true
        };
        return (
            <>
                <div className="gonggaolunbo">
                    <Carousel {...lunboSetting} autoplay>
                        {
                            this.state.list.map(item => (
                                <div key={item.noticeId} onClick={() => this.jumpNew(item.noticeId)}
                                    className="carouseltext">
                                    <img src={gonggaoicon} alt="" />
                                    {item.noticeTitle}
                                </div>
                            ))
                        }
                    </Carousel>
                </div>
            </>
        );
    }
}
export default withRouter(Newsitem)